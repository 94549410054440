import qs from "qs";
import router from "@/router";
import Clipboard from "clipboard";
import store from "store";
const postfix = "_deadtime";

const tool = {
	dom: {
		// 动态设置 页面标题
		setTitle(title) {
			document && (document.title = title);
		},
		// 显示
		show(obj) {
			obj.style.display = "";
		},

		// 隐藏
		hide(obj) {
			obj.style.display = "none";
		},

		// 判断是否有该 class
		hasClass(el, className) {
			const reg = new RegExp(`(^|\\s)${className}(\\s|$)`);
			return reg.test(el.className);
		},

		// 添加 class
		addClass(el, className) {
			if (this.hasClass(el, className)) {
				return;
			}
			const newClass = el.className.split(" ");
			newClass.push(className);
			el.className = newClass.join(" ");
		},

		// 删除类名
		removeClass(el, className) {
			if (this.hasClass(el, className)) {
				const reg = new RegExp(`(\\s|^)${className}(\\s|$)`);
				el.className = el.className.replace(reg, "");
			}
		},

		// 替换类名
		replaceClass(obj, newName, oldName) {
			this.removeClass(obj, oldName);
			this.addClass(obj, newName);
		},

		/**
		 * 获取/设置 自定义属性 data-${name}=val
		 * 如果传入了 val 就设置自定义属性 `data-${name}` 的值为 val
		 * 如果没有传入了 val 就获取自定义属性 `data-${name}` 的值
		 */
		customAttribute(el, name, val) {
			if (val) {
				return el.setAttribute(`data-${name}`, val);
			}
			return el.getAttribute(`data-${name}`);
		},

		/**
		 * 去抖（节流）
		 * 有一些浏览器事件可以很快地在短时间内多次触发，
		 * 例如调整窗口大小、向下滚动页面、搜索框节流等。
		 * 如果你将事件监听器绑定到窗口滚动事件，并且用户不断快速地向下滚动页面，
		 * 则你的事件可能在1秒内触发数千次。这可能会导致一些严重的性能问题。
		 * 解决这个问题的一种方法是去抖，通过限制再次调用函数之前必须经过的时间。
		 * 因此，去抖的正确实现是将几个函数调用组合成一个，并且在经过一段时间后仅执行一次。
		 *
		 * document.addEventListener('scroll', debounce(function () {
		 *   console.log('hello')
		 * }, 1000))
		 *
		 * @param  {Function} fn    要节流的函数
		 * @param  {number}   delay 延迟毫秒数
		 */
		debounce(fn, delay) {
			let timer = null;

			return (...args) => {
				if (timer) {
					clearTimeout(timer);
				}

				timer = setTimeout(() => {
					fn.apply(this, args);
				}, delay);
			};
		},

		/**
		 * 获取元素相对屏幕的位置
		 * let one = document.getElementById('one')
		 * console.log(getPos(one)) -> {top: 8, left: 8}
		 */
		getPos(elem) {
			if (!elem) return {
				left: 0,
				top: 0
			};

			let top = 0;
			let left = 0;

			top = elem.getBoundingClientRect().top;
			left = elem.getBoundingClientRect().left;

			return {
				top,
				left
			};
		},
		/**
		 * 键盘事件 keyCode 兼容性写法
		 */
		getKeyCode(e) {
			// e = e ? e : (window.event ? window.event : '')
			return e.keyCode ? e.keyCode : e.which;
		}
	},
	num: {

		/**
		 * 返回一个 [min, max] 之间的随机整数
		 * @param  {number} min 下界
		 * @param  {number} max 上界
		 */
		getRandomNum(min, max) {
			return Math.floor(Math.random() * (max - min + 1) + min);
		},
		transformToChinese(n) {
			if (!n) return 0;
			n = parseFloat(n);
			if (n >= 10000) {
				var sen = n / 10000; //过w处理
				var num = sen.toFixed(2) //保留一位小数
				n = num + "万"
			} else {
				return n;
			}
			return n;
		},
		/**
		 * 让两个整数交换数值
		 */
		convertInt(a = 1, b = 2) {
			a ^= b;
			b ^= a;
			a ^= b;
		},

		/**
		 * 符号相同
		 * 异或运算:同为 1 或 0 时候等于 0，否则为 1
		 * 如 a= 10110，b = 10011 , a ^ b = 00101
		 * 二进制第一位：1 表示负，0 表示正
		 *
		 * sameSign(1, 2) -> true
		 * sameSign(1, -2) -> false
		 */
		sameSign(a, b) {
			return (a ^ b) >= 0;
		},

		/**
		 * 向量
		 */
		vector(a, b) {
			return {
				x: b.x - a.x,
				y: b.y - a.y
			};
		},

		/**
		 * 向量叉乘公式
		 */
		vectorPro(v1, v2) {
			return v1.x * v2.y - v1.y * v2.x;
		},

		/**
		 * 点 p 是否在 △abc 中
		 * http://oph264zoo.bkt.clouddn.com/17-8-29/42222.jpg
		 * @param  {[type]}  p [description]
		 * @param  {[type]}  a [description]
		 * @param  {[type]}  b [description]
		 * @param  {[type]}  c [description]
		 * @return {Boolean}   [description]
		 */
		isPointInTrangle(p, a, b, c) {
			const pa = tool.num.vector(p, a);
			const pb = tool.num.vector(p, b);
			const pc = tool.num.vector(p, c);

			const t1 = tool.num.vectorPro(pa, pb);
			const t2 = tool.num.vectorPro(pb, pc);
			const t3 = tool.num.vectorPro(pc, pa);

			return tool.num.sameSign(t1, t2) && tool.num.sameSign(t2, t3) && tool.num.sameSign(t1, t3);
		},

		/**
		 * 格式化数字算法
		 */
		format: {
			/**
			 * 两个数字相加
			 */
			add(arg1, arg2) {
				let r1;
				let r2;
				let m = 0;
				try {
					r1 = arg1.toString().split(".")[1].length;
				} catch (e) {
					r1 = 0;
				}
				try {
					r2 = arg2.toString().split(".")[1].length;
				} catch (e) {
					r2 = 0;
				}
				m = 10 ** Math.max(r1, r2);
				return Number((arg1 * m + arg2 * m) / m);
			},

			/**
			 * 两个数字相减
			 */
			sub(arg1, arg2) {
				let r1;
				let r2;
				let m = 0;
				let n = 0;
				try {
					r1 = arg1.toString().split(".")[1].length;
				} catch (e) {
					r1 = 0;
				}
				try {
					r2 = arg2.toString().split(".")[1].length;
				} catch (e) {
					r2 = 0;
				}
				m = 10 ** Math.max(r1, r2);
				n = r1 >= r2 ? r1 : r2;
				return Number(((arg1 * m - arg2 * m) / m).toFixed(n));
			},

			/**
			 * 两个数字相乘
			 */
			mul(arg1, arg2) {
				let m = 0;
				const s1 = arg1.toString();
				const s2 = arg2.toString();
				try {
					m += s1.split(".")[1].length;
				} catch (e) {
					return false;
				}
				try {
					m += s2.split(".")[1].length;
				} catch (e) {
					return false;
				}
				return Number((Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) / 10 ** m);
			},

			/**
			 * 两个数字相除
			 */
			div(arg1, arg2) {
				let t1 = 0;
				let t2 = 0;
				let r1 = 0;
				let r2 = 0;
				try {
					t1 = arg1.toString().split(".")[1].length;
				} catch (e) {
					return false;
				}
				try {
					t2 = arg2.toString().split(".")[1].length;
				} catch (e) {
					return false;
				}
				r1 = Number(arg1.toString().replace(".", ""));
				r2 = Number(arg2.toString().replace(".", ""));
				return Number((r1 / r2) * 10 ** (t2 - t1));
			}
		}
	},
	arr: {
		// 克隆数组
		cloneArr(arr) {
			// 从第一个字符就开始 copy
			// slice(start,end) 方法可从已有的数组中返回选定的元素。
			return arr.slice(0);
		},

		/**
		 * 洗牌函数
		 * @param  {Array} arr 原数组
		 * @param  {boolean} flag 是否改变原数组，默认不改变
		 * @return {Array}     新数组
		 */
		shuffle(arr, flag = false) {
			let newArr = [];
			if (flag) {
				newArr = arr;
			} else {
				newArr = arr.slice(0);
			}

			for (let i = 0; i < newArr.length; i++) {
				const j = tool.num.getRandom(0, i);
				const temp = newArr[i];
				newArr[i] = newArr[j];
				newArr[j] = temp;
			}
			return newArr;
		},

		// 随机获取数组的一个成员
		randomOne(arr) {
			return arr[Math.floor(Math.random() * arr.length)];
		},

		// 数组去重
		removeRepeat(arr) {
			return Array.from(new Set(arr));
		},
		// [1,2,3,1,'a',1,'a'].filter(function(ele,index,array){
		//     return index===array.indexOf(ele)
		// })

		// 数组最大值
		maxArr(arr) {
			return Math.max.apply(null, arr);

			// var numbers = [5, 458 , 120 , -215 , 228 , 400 , 122205, -85411];
			// var maxInNumbers = Math.max.apply(Math, numbers);
			// var minInNumbers = Math.min.apply(Math, numbers);
		},

		// 数组最小值
		minArr(arr) {
			return Math.min.apply(null, arr);
		},

		// 数组数字总和 (必须保证数组每个元素都是 Number)
		sumArr(arr) {
			let sum = 0;

			for (let i = 0; i < arr.length; i++) {
				sum += arr[i];
			}

			return sum;
		},

		// 数组数字平均值，小数点可能会有很多位，这里不做处理，处理了使用就不灵活了！
		averageArr(arr) {
			const average = this.sumArr(arr) / arr.length;
			return average;
		},

		/**
		 * 一个元素出现的次数
		 * getEleCount('asd56+asdasdwqe', 'a') -> 3
		 * getEleCount([1, 2, 3, 4, 2], 3) -> 1
		 * @param  {[type]} obj 可以是对象或者数组
		 * @param  {[type]} ele [description]
		 * @return {[type]}     [description]
		 */
		getEleCount(obj, ele) {
			let num = 0;

			for (let i = 0; i < obj.length; i++) {
				if (ele === obj[i]) {
					num++;
				}
			}

			return num;
		},

		// 对象转数组
		objToArr(obj) {
			const arr = [];
			for (const i in obj) {
				arr[i] = obj[i];
			}
			return arr;
		},

		// 多数组合并
		parse(...array) {
			const result = [];
			for (let i = 0, l = array.length, arr; i < l; i++) {
				arr = array[i];
				for (let j = 0, l = arr.length; j < l; j++) {
					result.push(arr[j]);
				}
			}
			return result;
		},

		/**
		 * 按照时间重新分组二维数组
		 * @param {Object,Array} array 需要格式化的数据，可以是对象或者数组
		 * @param {String} field 数组中代表时间的字段，作为分组使用
		 * @param {String} style 需要格式化的时间格式
		 * @return {Object}
		 */
		// eslint-disable-next-line no-unused-vars
		timeToGroup(array, field = "time", style = "yyyy-MM") {
			let data = [];
			array.map(v => {
				// let date = tool.time.format(v[field], style)
				let date = v[field];
				let is = -1;
				data.map((v1, k1) => {
					if (v1.group === date) {
						is = k1;
					}
				});
				is > -1 ? data[is].children.push(v) : data.push({
					group: date,
					children: [v]
				});
			});
			return data;
		}
	},
	str: {
		/**
		 * 数字转中文
		 * @param  {INT} num  需要转化的数字
		 * @return {String}     返回中文数字
		 */
		numberfilter(num) {
			const changeNum = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"]; // changeNum[0] = "零"
			const unit = ["", "十", "百"];
			num = parseInt(num);
			const getWan = temp => {
				const strArr = temp
					.toString()
					.split("")
					.reverse();
				let newNum = "";
				for (var i = 0; i < strArr.length; i++) {
					newNum =
						(i == 0 && strArr[i] == 0 ?
							"" :
							i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ?
							"" :
							changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i])) + newNum;
				}
				return newNum;
			};
			const overWan = Math.floor(num / 100);
			let noWan = num % 100;
			if (noWan.toString().length < 2) noWan = "0" + noWan;
			let strr = overWan ? getWan(overWan) + "百" + getWan(noWan) : getWan(num);
			if (strr.split("")[0] == "一") {
				let showNum = "";
				if (strr == "一") {
					showNum = strr.substring(0);
				} else {
					showNum = strr.substring(1);
				}
				return showNum;
			} else {
				let showNum = overWan ? getWan(overWan) + "百" + getWan(noWan) : getWan(num);
				return showNum;
			}
		},
		/**
		 * 正则验证(可扩展)
		 * @param  {String} str  需要检测的字符串
		 * @param  {String} type 检测类型
		 * @return {Boolean}     返回布尔值
		 */
		testReg(str, type) {
			switch (type) {
				case "email":
					return /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(str);
				case "phone":
					return /^1[3|4|5|7|8][0-9]{9}$/.test(str);
				case "tel":
					return /^(0\d{2,3}-\d{7,8})(-\d{1,4})?$/.test(str);
				case "number":
					return /^[0-9]$/.test(str);
				case "english":
					return /^[a-zA-Z]+$/.test(str);
				case "chinese":
					return /^[\u4E00-\u9FA5]+$/.test(str);
				case "lower":
					return /^[a-z]+$/.test(str);
				case "upper":
					return /^[A-Z]+$/.test(str);
				default:
					return true;
			}
		},

		/**
		 * 检测密码强度
		 * checkPwdLv('12asdASAD') -> 3
		 * @param  {[type]} str [description]
		 * @return {[type]}     [description]
		 */
		checkPwdLv(str) {
			let nowLv = 0;

			// 密码长度 < 6，强度为 0
			if (str.length < 6) {
				return nowLv;
			}
			// 密码长度 >= 6，全为数字，强度加 1
			if (/[0-9]/.test(str)) {
				nowLv++;
			}
			// 密码长度 >= 6，全为小写字母，强度加 1
			if (/[a-z]/.test(str)) {
				nowLv++;
			}
			// 密码长度 >= 6，全为大写字母，强度加 1
			if (/[A-Z]/.test(str)) {
				nowLv++;
			}

			return nowLv;
		},

		/**
		 * 产生随机颜色
		 * #d9d264 #d09293 #0e608e
		 * @param  {Number} need 1:十六进制颜色值 2:RGB颜色值
		 */
		getRandomColor(need = 1) {
			if (need === 1) {
				return `#${Math.random()
          .toString(16)
          .substr(2, 6)}`;
			}
			if (need === 2) {
				return `rgb(${this.getRandom(0, 255)}, ${this.getRandom(0, 255)}, ${this.getRandom(
          0,
          255
        )})`;
			}
		},

		/**
		 * 返回一个随机字符串
		 * @param  {Number} n      字符数量 [0, 13]
		 * @param  {String} prefix 随机字符串前缀
		 * @return {[type]}        [description]
		 */
		getRandomStr(n = 5, prefix = "") {
			return (
				prefix +
				Math.random()
				.toString(16)
				.substr(2, n)
			);
		},

		/**
		 * 动态创建 <script>
		 */
		createScript(url, charset = "utf-8") {
			const script = document.createElement("script");
			script.setAttribute("type", "text/javascript");
			script.setAttribute("charset", charset);
			script.setAttribute("src", url);
			script.async = true;

			return script;
		},

		/**
		 * 单行写一个评级组件
		 * rate [1, 5]
		 * 传入一个 1-5 的数字
		 * @return {[type]} [description]
		 */
		getStar(rate = 1) {
			"★★★★★☆☆☆☆☆".slice(5 - rate, 10 - rate);
		},

		/**
		 * 去掉前后空格
		 * 1:前后空格(默认)  2:所有空格  3:前空格 4:后空格
		 * @param  {[type]} str  [description]
		 * @param  {Number} type [description]
		 * @return {[type]}      [description]
		 */
		trim(str, type = 1) {
			switch (type) {
				case 1:
					return str.replace(/(^\s*)|(\s*$)/g, "");
				case 2:
					return str.replace(/\s+/g, "");
				case 3:
					return str.replace(/(^\s*)/g, "");
				case 4:
					return str.replace(/(\s*$)/g, "");
				default:
					return str;
			}
		},

		/**
		 * 大小写转换
		 * 1:首字母大写 2:首页母小写 3:大小写转换 4:全部大写 5:全部小写
		 */
		changeCase(str, type) {
			function ToggleCase() {
				let itemText = "";
				str.split("").forEach(item => {
					if (/^([a-z]+)/.test(item)) {
						itemText += item.toUpperCase();
					} else if (/^([A-Z]+)/.test(item)) {
						itemText += item.toLowerCase();
					} else {
						itemText += item;
					}
				});
				return itemText;
			}

			switch (type) {
				case 1:
					return str.replace(/^(\w)(\w+)/, (v, v1, v2) => v1.toUpperCase() + v2.toLowerCase());
				case 2:
					return str.replace(/^(\w)(\w+)/, (v, v1, v2) => v1.toLowerCase() + v2.toUpperCase());
				case 3:
					return ToggleCase(str);
				case 4:
					return str.toUpperCase();
				case 5:
					return str.toLowerCase();
				default:
					return str;
			}
		},

		/**
		 * HTML 实体字符转义
		 */
		htmlEncode(str) {
			let s = "";
			if (str.length === 0) {
				return "";
			}
			s = str.replace(/&/g, "&amp;");
			s = str.replace(/>/g, "&gt;");
			s = str.replace(/</g, "&lt;");
			s = str.replace(/\s/g, "&nbsp;");
			// s = str.replace(/\'/g, '&#39;')
			// s = str.replace(/\"/g, '&quot;')
			s = str.replace(/\n/g, "<br>");
			return s;
		},

		/**
		 * 如何装逼用代码骂别人SB
		 * http://www.jfh.com/jfperiodical/article/3224
		 * @return {[type]} [description]
		 */
		getSB() {
			console.log((!~+[] + {})[--[~+""][+[]] * [~+[]] + ~~!+[]] + ({} + [])[[~!+[]] * ~+[]]);
		},

		/**
		 * 如何用代码优雅的证明自己NB
		 * @return {[type]} [description]
		 */
		getNB() {
			console.log(([][
				[]
			] + [])[+!![]] + ([] + {})[!+[] + !![]]);
		},

		/**
		 * 如何优雅的实现金钱格式化
		 * 1234567890 --> 1,234,567,890
		 * @return {[type]}       [description]
		 */
		formatMoney(str = "1234567890") {
			return str.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // 1,234,567,890

			// return str.split('').reverse().reduce((prev, next, index) => {
			// return ((index % 3) ? next : (next + ',')) + prev
			// })
		},

		/**
		 * 实现标准JSON的深拷贝
		 * 不考虑IE的情况下，标准JSON格式的对象蛮实用，不过对于undefined和function的会忽略掉。
		 * @param  {[type]} a [description]
		 * @return {[type]}   [description]
		 */
		deepCopy(a) {
			return JSON.parse(JSON.stringify(a));

			// var a = {
			//     a: 1,
			//     b: { c: 1, d: 2 }
			// }
			// var b=JSON.parse(JSON.stringify(a))
		},
		isEmptyObject(obj) {
			for (const key in obj) {
				return;
			}
			return true;
		},

		async copy(txt, e = ".text-clone") {
			return new Promise((resolve, reject) => {
				var clipboard = new Clipboard(e, {
					text: function() {
						return txt;
					}
				});
				clipboard.on("success", e => {
					resolve(e);
					// 释放内存
					clipboard.destroy();
				});
				clipboard.on("error", e => {
					// 不支持复制
					reject(e);
					console.log("该浏览器不支持自动复制");
					// 释放内存
					clipboard.destroy();
				});
			});
		}
	},
	time: {
		/**
		 * 两个时间做比较，DateRefer默认是当前时间
		 * @param {*} DateStr 时间
		 * @param {*} DateRefer 比较时间
		 * @returns 
		 */
		isDefDate(DateStr, DateRefer = new Date()) {
			const paramDate = new Date(DateStr.replace(/-/g, "/"));
			if (DateRefer >= paramDate) {
				return true;
			}
			return false;
		},
		/* 
		 * formatDate('2024-02-16 15:34:55') // 输出：2月16日
		 * formatDate('2023-02-16 15:34:55') // 输出：2023年2月16日
		 * formatDate('2024-02-16 15:34:55', 'hour') // 输出：2月16日 15时
		 * formatDate('2024-02-16 15:34:55', 'minute') // 输出：2月16日 15:34
		 * formatDate('2024-02-16 15:34:55', 'second')) // 输出：2月16日 15:34:55
		 */
		formatDate(dateString, type = '') {
			const date = new Date(dateString.replace(/-/g, '/'));
			const year = date.getFullYear();
			const month = date.getMonth() + 1;
			const day = date.getDate();
			const hour = date.getHours();
			const minute = date.getMinutes();
			const second = date.getSeconds();

			const formatDate = year === new Date().getFullYear() ?
				`${month}月${day}日` :
				`${year}年${month}月${day}日`
			const formatType = {
				'': formatDate,
				'hour': `${formatDate} ${hour}时`,
				'minute': `${formatDate} ${hour}:${minute}`,
				'second': `${formatDate} ${hour}:${minute}:${second}`
			};

			return formatType[type] || formatType[''];
		},

		/**
		 * 时间格式化方法
		 *
		 * @param {(Object|string|number)} time
		 * @param {String} cFormat
		 * @returns {String | null}
		 */
		parseTime(time, cFormat) {
			if (arguments.length === 0) {
				return null;
			}

			let date;
			const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";

			if (typeof time === "object") {
				date = time;
			} else {
				if (typeof time === "string" && /^[0-9]+$/.test(time)) {
					time = parseInt(time);
				}
				if (typeof time === "number" && time.toString().length === 10) {
					time = time * 1000;
				}

				date = new Date(time.replace(/-/g, "/"));
			}

			const formatObj = {
				y: date.getFullYear(),
				m: date.getMonth() + 1,
				d: date.getDate(),
				h: date.getHours(),
				i: date.getMinutes(),
				s: date.getSeconds(),
				a: date.getDay()
			};

			const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
				const value = formatObj[key];
				// Note: getDay() returns 0 on Sunday
				if (key === "a") {
					return ["日", "一", "二", "三", "四", "五", "六"][value];
				}

				return value.toString().padStart(2, "0");
			});

			return time_str;
		},
		/**
		 * 获取当前时间
		 * 2017-08-11 22:52:13 星期六
		 * @param  {Boolean} hasDay  是否需要年月日
		 * @param  {Boolean} hasHour 是否需要十分秒
		 * @param  {Boolean} hasWeek 是否需要星期
		 * @param  {String} sign1 分隔符
		 * @param  {String} sign2 分隔符
		 */
		getNowDate(hasDay = true, hasHour = true, hasWeek = true, sign1 = "-", sign2 = ":") {
			const date = new Date();
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, "0");
			const day = date
				.getDate()
				.toString()
				.padStart(2, "0");
			const hour = date
				.getHours()
				.toString()
				.padStart(2, "0");
			const minutes = date
				.getMinutes()
				.toString()
				.padStart(2, "0");
			const seconds = date
				.getSeconds()
				.toString()
				.padStart(2, "0");
			const weekArr = ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期天"];
			const week = weekArr[date.getDay()];

			const time1 = hasDay ? `${year}${sign1}${month}${sign1}${day}` : "";
			const time2 = hasHour ? `${hour}${sign2}${minutes}${sign2}${seconds}` : "";
			const time3 = hasWeek ? `${week}` : "";

			return `${time1} ${time2} ${time3}`.replace(/(^\s*)|(\s*$)/g, "");
		},

		/**
		 * 格式化时间戳 (分:秒)
		 * 61 -> 01:01
		 * @param  {Number} timestamp 时间戳
		 * @param  {String} sign      分隔符，默认 :
		 * @return {[type]}           [description]
		 */
		timestampFormat(timestamp, sign = ":", pad = true) {
			timestamp = Math.floor(timestamp);

			let minute = Math.floor(timestamp / 60).toString();
			let second = (timestamp % 60).toString();

			pad && (minute = minute.padStart(2, "0"), second = second.padStart(2, "0"));
			return `${minute}${sign}${second}`;
		},

		/**
		 * 倒计时
		 * countDown('2017-8-11 24:0:0') -> 剩余0天0小时54分钟41秒
		 * @param  {Date} endTime 结束时间
		 * @return {[type]}         [description]
		 */
		countDown(endTime, type = "str") {
			const start = new Date();
			const end = new Date(endTime);
			console.log(end);
			const dif = end.getTime() - start.getTime();

			let d = 0;
			let h = 0;
			let m = 0;
			let s = 0;

			if (dif >= 0) {
				d = Math.floor(dif / 1000 / 3600 / 24);
				h = Math.floor((dif / 1000 / 60 / 60) % 24);
				m = Math.floor((dif / 1000 / 60) % 60);
				s = Math.floor((dif / 1000) % 60);
			}
			if (type == "str") return `仅剩${d}天${h}小时${m}分钟${s}秒`;
			return [d, h, m, s];
		},

		// 获取时间戳
		timestamp(t) {
			let time = new Date().getTime();
			return t ? time : Number(time.toString().substring(0, 10));
		},

		// 验证码倒计时
		async timeInterval(second, time, bandle) {
			return new Promise(resolve => {
				let vm = setInterval(() => {
					second--;
					typeof bandle === "function" && bandle(second);
					if (second < 1) {
						clearInterval(vm);
						second = time;
						resolve(second);
					}
				}, 1000);
			});
		},

		// 格式化日期 time时间，style格式 yyyy-MM-dd hh-mm-ss
		format(time, style) {
			let date = new Date(time);
			var o = {
				"M+": date.getMonth() + 1, //月份
				"d+": date.getDate(), //日
				"h+": date.getHours(), //小时
				"m+": date.getMinutes(), //分
				"s+": date.getSeconds(), //秒
				"q+": Math.floor((date.getMonth() + 3) / 3), //季度
				S: date.getMilliseconds() //毫秒
			};
			if (/(y+)/.test(style))
				style = style.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
			for (var k in o)
				if (new RegExp("(" + k + ")").test(style))
					style = style.replace(
						RegExp.$1,
						RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
					);
			return style;
		}
	},
	http: {
		// 给对应的参数后面增加元素
		params(data) {
			const params = new URLSearchParams();
			for (const key in data) {
				params.append(key, data[key]);
			}
			return params;
		},
		/**
		 * 设置 url 参数
		 * setUrlParam({a:1,b:2}) -> a=1&b=2
		 * @param {[type]} obj 传入一个对象
		 */
		setUrlParam(obj) {
			const query = [];

			for (const i in obj) {
				if (obj[i] != null && obj[i] !== "") {
					query.push(`${i}=${obj[i]}`);
				}
			}

			return query.join("&");
		},

		/**
		 * 解析 URL 参数
		 * http://localhost:8080/?id=123&key=vaule#/restaurant/seller
		 * "?id=123&key=vaule"
		 * {id: "123", key: "vaule"}
		 */
		urlParse(url = window.location.search) {
			// window.location.href 当前文件的绝对地址
			// window.location.search 当前文件的哈希地址
			const obj = {};
			const reg = /[?&][^?&]+=[^?&]+/g;
			const arr = url.match(reg); // ["?id=123", "&key=vaule"]

			if (arr) {
				arr.forEach(item => {
					// 删除 ? 和 &，以 = 为标志分割数组
					const tempArr = item.substring(1).split("="); // ["id", "123"]  ["key", "vaule"]
					// 使用 decodeURIComponent() 对编码后的 URI 进行解码
					const key = decodeURIComponent(tempArr[0]);
					const value = decodeURIComponent(tempArr[1]);
					obj[key] = value;
				});
			}
			return obj;
		},
		urlDel(url, parameter) {
			var urlparts = url.split("?");
			if (urlparts.length >= 2) {
				//参数名前缀
				var prefix = encodeURIComponent(parameter) + "=";
				var pars = urlparts[1].split(/[&;]/g);

				//循环查找匹配参数
				for (var i = pars.length; i-- > 0;) {
					if (pars[i].lastIndexOf(prefix, 0) !== -1) {
						//存在则删除
						pars.splice(i, 1);
					}
				}

				return urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
			}
			return url;
		},
		// 在url的后缀增加get参数 或删除get参数
		urlAdd(d, del, t = "http", u) {
			let url;
			!u && (url = window.location.href);
			let p = tool.http.urlParse(url);
			del && delete p[del];
			// console.log('d:::::::',d);
			// console.log('p:::::::',p);
			let n = Object.assign({}, d, p);
			// console.log('n::::::::',n);
			if (t === "http") {
				return `${u ? u : tool.http.url("pathall")}${tool.obj.JsonNum(n) > 0 ? "?" : ""
          }${qs.stringify(n)}`;
			} else {
				return n;
			}
		},
		// 更新url的后缀
		paramsUpdate(key, value, u) {
			let url;
			!u && (url = window.location.href);
			let params = tool.http.urlParse(url);
			params && delete params[key];
			params[key] = value;
			return `${u ? u : tool.http.url("pathall")}${tool.obj.JsonNum(params) > 0 ? "?" : ""
        }${qs.stringify(params)}`;
		},
		// 获取域名，地址
		url(t) {
			switch (t) {
				case "host":
					return `${window.location.protocol}//${window.location.host}`;
				case "pathall":
					return `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
				case "path":
					return `${window.location.pathname}`;
				case "all":
					return `${window.location.protocol}//${window.location.host}${router.app.$route.fullPath}`;
				default:
					return `${window.location.href}`;
			}
		}
	},
	storage: {
		// 存储 localstorage
		// localStorage.key="value"
		// localStorage.setItem(key, value)
		set(key = "__test__", value, t) {
			store.set(key, value);
			var seconds = parseInt(t);
			if (seconds > 0) {
				var timestamp = Date.parse(new Date());
				timestamp = timestamp / 1000 + seconds;
				store.set(key + postfix, timestamp + "");
			} else {
				store.remove(key + postfix);
			}
		},

		// 获取 localstorage
		// localStorage.key
		get(key = "__test__", def = "") {
			try {
				var deadtime = store.get(key + postfix);
				if (deadtime) {
					if (parseInt(deadtime) < Date.parse(new Date()) / 1000) {
						store.remove(key + postfix);
						store.remove(key);
						if (def) {
							return def;
						} else {
							return "";
						}
					}
				}
				let str = store.get(key);
				// str 不存在
				if (typeof str == "number") return str;
				if (!str) return def;
				return str;
			} catch (e) {
				return def;
			}
		},

		// 删除单个数据 localstorage
		// localStorage.removeItem("key");
		remove(key = "__test__") {
			if (key instanceof Array) {
				key.map(k => {
					store.remove(k);
					store.remove(k + postfix);
				});
			} else {
				store.remove(key);
				store.remove(key + postfix);
			}
		},
		removeApi() {
			store.each(function(value, key) {
				if (key.indexOf("api_") > -1) {
					tool.storage.remove(key);
				}
			});
		},
		// 删除所有数据 localstorage
		clear() {
			store.clearAll();
		}
		// set(key, value) {
		//   localStorage.setItem(key, JSON.stringify(value));
		// },
		// get(key) {
		//   return JSON.parse(localStorage.getItem(key));
		// },
		// remove(key) {
		//   localStorage.removeItem(key)
		// },
		// clear() {
		//   localStorage.clear()
		// }
	},
	event: {
		// event 兼容
		getEvent(event) {
			return event || window.event;
		},

		// type 兼容
		getType(event) {
			return event.type;
		},

		// target 兼容
		getTarget(event) {
			return event.target ? event.target : event.srcElement;
		},

		// 添加事件句柄
		addHandler(elem, type, listener) {
			if (elem.addEventListener) {
				elem.addEventListener(type, listener, false);
			} else if (elem.attachEvent) {
				elem.attachEvent(`on${type}`, listener);
			} else {
				// 在这里由于.与'on'字符串不能链接，只能用 []
				elem[`on${type}`] = listener;
			}
		},

		// 移除事件句柄
		removeHandler(elem, type, listener) {
			if (elem.removeEventListener) {
				elem.removeEventListener(type, listener, false);
			} else if (elem.detachEvent) {
				elem.detachEvent(`on${type}`, listener);
			} else {
				elem[`on${type}`] = null;
			}
		},

		// 添加事件代理
		addAgent(elem, type, agent, listener) {
			elem.addEventListener(type, e => {
				if (e.target.matches(agent)) {
					listener.call(e.target, e); // this 指向 e.target
				}
			});
		},

		// 取消默认行为
		preventDefault(event) {
			if (event.preventDefault) {
				event.preventDefault();
			} else {
				event.returnValue = false;
			}
		},

		// 阻止事件冒泡
		stopPropagation(event) {
			if (event.stopPropagation) {
				event.stopPropagation();
			} else {
				event.cancelBubble = true;
			}
		}
	},
	others: {
		/**
		 * 获取浏览器信息
		 * @return {string} 浏览器名称
		 */
		whatBrowser() {
			const BrowserAgent = navigator.userAgent; // 返回由客户机发送服务器的 user-win-agent 头部的值。
			// let BrowserName = navigator.appName // 返回浏览器的名称。
			// let BrowserCode = navigator.appCodeName // 返回浏览器的代码名。
			// let BrowserVersion = navigator.appVersion // 返回浏览器的平台和版本信息。
			// let BrowserCookie = navigator.cookieEnabled // 返回指明浏览器中是否启用 cookie 的布尔值。
			// let BrowserOnline = navigator.onLine //返回指明系统是否处于脱机模式的布尔值。
			// let BrowserPlatform = navigator.platform // 返回运行浏览器的操作系统平台。
			// let BrowserLanguage = navigator.language // 返回 OS 的自然语言设置。

			let browser = "Failed to identify the browser";

			if (BrowserAgent.indexOf("Firefox") > -1) {
				browser = "Firefox";
			} else if (BrowserAgent.indexOf("Chrome") > -1) {
				browser = "Chrome";
			} else if (BrowserAgent.indexOf("MSIE") > -1 && BrowserAgent.indexOf("Trident") > -1) {
				browser = "IE(8-10)";
			}

			return browser;
		},
		// 设置延迟执行
		wait(time) {
			return new Promise((r) => {
				setTimeout(() => {
					r(time)
				}, time)
			})
		},

	},
	obj: {
		objectToString(obj) {
			let str = "";
			Object.keys(obj).map(key => {
				str += `${key}=${obj[key]}&`;
			});
			return str.replace(/&$/, "");
		},
		isNullObj(obj) {
			return Object.keys(obj).length === 0;
		},
		isJsonString(str) {
			try {
				JSON.parse(str);
				return true;
			} catch (err) {
				return false;
			}
		},
		/**
		 *重制对象中的key 值为空
		 *
		 * @param {*} [datas=[]]
		 * @returns
		 */
		reset(datas = []) {
			datas.map(data => {
				for (const key in data) {
					if (data.hasOwnProperty.call(key, key)) {
						switch (typeof data[key]) {
							case "string":
								data[key] = "";
								break;
								// case 'number': data[key] = 0;break;
							case "object":
								Array.isArray(data[key]) ?
									(data[key] = []) :
									data[key] == null ?
									(data[key] = "") :
									(data[key] = {});
								break;
							default:
								data[key] = "";
								break;
						}
					}
				}
				return true;
			});
			return datas;
		},
		JsonNum(json) {
			let jsonLength = 0;
			// eslint-disable-next-line no-unused-vars
			for (var i in json) {
				jsonLength++;
			}
			return jsonLength;
		}
	},
	// 获取对象数据
	get(source, property, def = "") {
		let arr = property.split(".");
		if (!arr.length) return def;
		let tmp = source != null ? source[arr[0]] : undefined;
		arr.shift();
		arr.map(key => {
			tmp = tmp != null ? tmp[key] : undefined;
		});
		return tmp != null ? tmp : def;
	},

	img: {
		imgUrlToBase64(url) {
			return new Promise((resolve, reject) => {
				if (!url) {
					reject("请传入url内容");
				}
				if (/\.(png|jpe?g|gif|svg)(\?.*)?$/.test(url)) {
					// 图片地址
					const image = new Image();
					// 设置跨域问题
					image.setAttribute("crossOrigin", "anonymous");
					// 图片地址
					image.src = url;
					image.onload = () => {
						const canvas = document.createElement("canvas");
						const ctx = canvas.getContext("2d");
						canvas.width = image.width;
						canvas.height = image.height;
						ctx.drawImage(image, 0, 0, image.width, image.height);
						// 获取图片后缀
						const ext = url.substring(url.lastIndexOf(".") + 1).toLowerCase();
						// 转base64
						const dataUrl = canvas.toDataURL(`image/${ext}`);
						resolve(dataUrl || "");
					};
				} else {
					// 非图片地址
					reject("非(png/jpe?g/gif/svg等)图片地址");
				}
			});
		},
		down(img) {
			var image = new Image();
			// 解决跨域 Canvas 污染问题
			image.setAttribute("crossOrigin", "anonymous");
			image.onload = function() {
				var canvas = document.createElement("canvas");
				canvas.width = image.width;
				canvas.height = image.height;
				var context = canvas.getContext("2d");
				context.drawImage(image, 0, 0, image.width, image.height);
				var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

				var a = document.createElement("a"); // 生成一个a元素
				var event = new MouseEvent("click"); // 创建一个单击事件
				a.download = "x-img"; // 设置图片名称
				a.href = url; // 将生成的URL设置为a.href属性
				a.dispatchEvent(event); // 触发a的单击事件
			};
			image.src = img;
		}
	}
};

export default tool;