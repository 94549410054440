import {
	createStore
} from 'vuex'
export default createStore({
	state: {
		// 页面是否准备好显示 是否自适应完毕 否则会导致抖动
		appShow: false,
		//tabs底部导航栏
		tabsShow: false,
		swipListx:[],
		iscourse:true,//是否购买课程
		userInfo:{},//用户信息
		// 音频
		floatAudio: {
			id: 0,
			isPlay: false,
			playTime: 0,
			duration: 0,
			url: null,
			audio: {},
			isbubble: false
		},
		// 全局唯一的音频
		audioVux: {
			id: 0,
			isPlay: false,
			store: [],
			isStore: false, //是否缓存音频
			currentTime: 0,
			audio: {},
			duration:0,
		},
		audioVux1: {
			id: 0,
			isPlay: false,
			store: [],
			isStore: false, //是否缓存音频
			currentTime: 0,
			duration:0,
			audio: {},
		},
		// 全局播放音频
		allAudio:{
			id:0,
			url:"",
			currentTime: 0,
			duration:0,
			isPlay: false,
			audio: {},
			info:{},
			//缓存音频不需要
			store: [],
			isStore: false, //是否缓存音频
		},
		isread:false,//是否打开推荐朗读
		//最新上传完待合成的作品
		ossAudio: {
			src: '',
			time: 0
		},

		//支付loading
		showLoadingPay: null,
		//上传进度
		progress: 0,

		// 定时器
		timing: {
			timer: null,
			step: 5000,
		},

		// 任务反馈id
		task_id: 0,


	},
	getters: {},
	mutations: {

		setAppShow(state, v) {
			state.appShow = v;
		},
		setTaskId(state, v) {
			state.task_id = v;
		},
		//设置上传进度
		setProgress(state, v) {
			state.progress = parseInt(v);
			// if (parseInt(state.progress) == 100) state.progress = 0;
		},

		// 音频操作
		audio(state, d = 'switch') {
			let ts = ["play", "pause", "switch", "stop", "autoplay"]
			let isPlay = state.audioVux.isPlay
			let id = state.audioVux.id
			let store = state.audioVux.store // 缓存的时间 
			let por;
			switch (ts.indexOf(d)) {
				case -1: // 初始化
					isPlay && (
						(state.audioVux.audio.pause(), isPlay = false),
						(state.audioVux.audio.currentTime = 0, state.audioVux.currentTime = 0)
					)
					state.audioVux.audio = new Audio(d);
					break;
				case 0:
					state.audioVux.audio.play(), isPlay = true
					break;
				case 1:
					state.audioVux.audio.pause(), isPlay = false
					break;
				case 2:
					isPlay ? state.audioVux.audio.pause() : state.audioVux.audio.play()
					isPlay = !isPlay
					break;
				case 3:
					state.audioVux.audio.pause(), isPlay = false
					state.audioVux.audio.currentTime = 0
					state.audioVux.currentTime = 0
					break;
				case 4:
					if (isPlay) {
						state.audioVux.audio.pause();
						isPlay = !isPlay
					} else {
						//监听播放成功后再设置播放状态 防止自动播放失败但状态变化
						state.audioVux.audio.play().then(() => {
							state.audioVux.isPlay = !state.audioVux.isPlay
						})
					}
					break;
			}
			state.audioVux.isPlay = isPlay
			state.audioVux.audio.addEventListener("timeupdate", function() {
				// 弹框播放背景音乐值没有变化,故此判断 让播放状态更新
				// if (!state.audioVux.isPlay && state.audioVux.currentTime != 0) {
				// 	state.audioVux.isPlay = true
				// }
				//设置音频缓存
				isPlay && state.audioVux.isStore && (store[id] = state.audioVux.audio.currentTime);
				state.audioVux.currentTime = state.audioVux.audio.currentTime
			});
			state.audioVux.audio.addEventListener("ended", function() {
				state.audioVux.isPlay = false;
			});
		},
		// 全局音频操作
		audioAll(state, d = 'switch') {
			let ts = ["play", "pause", "switch", "stop", "autoplay"]
			let isPlay = state.allAudio.isPlay
			let id = state.allAudio.id
			let store = state.allAudio.store // 缓存的时间 
			let por;
			switch (ts.indexOf(d)) {
				case -1: // 初始化
					isPlay && (
						(state.allAudio.audio.pause(), isPlay = false),
						(state.allAudio.audio.currentTime = 0, state.allAudio.currentTime = 0)
					)
					state.allAudio.audio = new Audio(d);
					break;
				case 0:
					state.allAudio.audio.play(), isPlay = true
					break;
				case 1:
					state.allAudio.audio.pause(), isPlay = false
					break;
				case 2:
					isPlay ? state.allAudio.audio.pause() : state.allAudio.audio.play()
					isPlay = !isPlay
					break;
				case 3:
					state.allAudio.audio.pause(), isPlay = false
					state.allAudio.audio.currentTime = 0
					state.allAudio.currentTime = 0
					break;
				case 4:
					if (isPlay) {
						state.allAudio.audio.pause();
						isPlay = !isPlay
					} else {
						//监听播放成功后再设置播放状态 防止自动播放失败但状态变化
						state.allAudio.audio.play().then(() => {
							state.allAudio.isPlay = !state.allAudio.isPlay
						})
					}
					break;
			}
			state.allAudio.isPlay = isPlay
			state.allAudio.audio.addEventListener("timeupdate", function() {
				// 弹框播放背景音乐值没有变化,故此判断 让播放状态更新
				
				//设置音频缓存
				isPlay && state.allAudio.isStore && (store[id] = state.allAudio.audio.currentTime);
				state.allAudio.currentTime = state.allAudio.audio.currentTime
			});
			state.allAudio.audio.addEventListener("ended", function() {
				state.allAudio.isPlay = false;
			});
		},
		// 音频操作
		audioNew(state, d = 'switch') {
			let ts = ["play", "pause", "switch", "stop", "autoplay"]
			let isPlay = state.audioVux1.isPlay
			let id = state.audioVux1.id
			let store = state.audioVux1.store // 缓存的时间 
			let por;
			switch (ts.indexOf(d)) {
				case -1: // 初始化
					isPlay && (
						(state.audioVux1.audio.pause(), isPlay = false),
						(state.audioVux1.audio.currentTime = 0, state.audioVux1.currentTime = 0)
					)
					state.audioVux1.audio = new Audio(d);
					break;
				case 0:
					state.audioVux1.audio.play(), isPlay = true
					break;
				case 1:
					state.audioVux1.audio.pause(), isPlay = false
					break;
				case 2:
					isPlay ? state.audioVux1.audio.pause() : state.audioVux1.audio.play()
					isPlay = !isPlay
					break;
				case 3:
					state.audioVux1.audio.pause(), isPlay = false
					state.audioVux1.audio.currentTime = 0
					state.audioVux1.currentTime = 0
					break;
				case 4:
					if (isPlay) {
						state.audioVux1.audio.pause();
						isPlay = !isPlay
					} else {
						//监听播放成功后再设置播放状态 防止自动播放失败但状态变化
						state.audioVux1.audio.play().then(() => {
							state.audioVux1.isPlay = !state.audioVux1.isPlay
						})
					}
					break;
			}
			state.audioVux1.isPlay = isPlay
			state.audioVux1.audio.addEventListener("timeupdate", function() {
				// 弹框播放背景音乐值没有变化,故此判断 让播放状态更新
				
				//设置音频缓存
				isPlay && state.audioVux1.isStore && (store[id] = state.audioVux1.audio.currentTime);
				state.audioVux1.currentTime = state.audioVux1.audio.currentTime
			});
			state.audioVux1.audio.addEventListener("ended", function() {
				state.audioVux1.isPlay = false;
			});
		},
		//用户语音评论 音频操作
		audioWxp(state, src = '') {
			//关闭正在播放的其他音频 
			if (state.audioVux.isPlay) {
				state.audioVux.audio.pause()
				state.audioVux.isPlay = false
			}
			//初始化录音评论音频
			if (src != '') {
				state.audioWX.audio = new Audio(src);
			}
			let isPlay = state.audioWX.isPlay
			isPlay
				?
				state.audioWX.audio.pause() :
				state.audioWX.audio.play()
			state.audioWX.isPlay = !state.audioWX.isPlay
			state.audioWX.audio.addEventListener("timeupdate", function() {
				state.audioWX.currentTime = state.audioWX.audio.currentTime;
			});
			state.audioWX.audio.addEventListener("ended", function() {
				state.audioWX.isPlay = false;
			});
		},
		//计时器
		setStep(state, step) {
			state.timing.step = step;
		},
		setTimer(state, timer) {
			state.timing.timer = timer;
		},
		clearTimer(state) {
			clearInterval(state.timing.timer);
			state.timing.timer = null;
		},

	},
	actions: {
		startTimer({
			commit,
			state
		}, callback) {
			// 检查定时器是否已经启动
			if (state.timing.timer === null) {
				const timer = setInterval(() => {
					callback();
				}, state.timing.step);
				commit('setTimer', timer); // 保存定时器引用到 state
			}
		},
		stopTimer({
			commit
		}) {
			commit('clearTimer');
		},
	},
	modules: {}
})