import {
	createRouter,
	createWebHistory
} from 'vue-router'
import {
	closeToast
} from 'vant';
import routes from './routes'
import store from '@/store';


const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})
// 在路由跳转之前执行的逻辑
router.beforeEach((to, from, next) => {
	// 关闭当前弹窗
	closeToast()
	// console.log(to)
	// if (to.fullPath == "/my/create") {

	// }
	// 判断是否开启音乐
	if (store.state.audioVux.isPlay) {
		store.state.audioVux.isPlay = false;
		store.state.audioVux.audio.pause();
		//关闭音乐
		store.commit("audioVuxDel");
	}
	if (store.state.audioVux1.isPlay) {
		store.state.audioVux1.isPlay = false
		store.state.audioVux1.audio.pause();
	}
	// 修改页面标题
	document.title = to.meta.title
	next();
});

export default router