const routesContext = require.context('./', false, /\.js$/);

const routes = routesContext.keys().reduce((allRoutes, routeFile) => {
  // 忽略自身（index.js）
  if (routeFile === './index.js') return allRoutes;

  // 导入路由配置文件
  const routeConfig = routesContext(routeFile);

  // 将每个文件导出的路由数组合并到 allRoutes 数组中
  allRoutes.push(...(routeConfig.default || routeConfig));

  return allRoutes;
}, []);

export default routes;
