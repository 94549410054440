import mine from "./apis/mine";
import common from "./apis/common";
import course from "./apis/course";
import more from "./apis/more";
import audio from "./apis/audio";
import student from "./apis/student";
import msy from "./apis/msy";
import home from "./apis/home.js"
import my from "./apis/my.js"
import live from "./apis/live.js"
import questionnaire from "./apis/questionnaire.js"

const api = {
  mine,
  common,
  course,
  more,
  audio,
  student,
  msy,
  home,
  my,
  questionnaire,
  live
};
export default api;
