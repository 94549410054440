import {
	showToast,
	FloatingBubble,
	showDialog
} from "vant";
import login from "@/common/login";
import routes from '@/router/routes'
import tabsin from "@/components/tabs/tabs.vue"
import suspension from "@/components/common/suspension/suspension.vue"
import _ from "lodash";
export default {
	components: {
		[FloatingBubble.name]: FloatingBubble,
		tabsin,
		suspension
	},
	data() {
		return {
			bubble: false,
			offset: {
				x: 340,
				y: 300
			},

			// 欢迎弹窗
			wellcomShow: false,
			Blessings: [
				'心灵深处的宁静自由',
				'心中有爱，生活有光',
				'身心喜悦，幸福长久',
				'我有一颗追寻梦想的心',
				'有声传奇充满新奇的朗读营',
				'我超级热爱朗读',
				'心灵深处的宁静与自由',
				'心中充满爱，生活充满光芒',
				'身心愉悦，幸福长久',
				'我怀抱着追寻梦想的心',
				'我热爱朗读，充满激情',
				'心中有梦，生活充满希望',
				'我对朗读充满热情',
				'心灵深处，宁静如水',
				'心中充满爱，生命充满阳光',
				'身心愉悦，幸福长存',
				'我怀抱着追求梦想的心',
				'热爱朗读，演绎奇妙的世界',
				'心中充满热爱，对朗读情有独钟',
				'心中有梦想，生活充满希望',
				'内心深处，如同宁静的湖泊',
				'心中充满爱意，生活充满温暖',
				'身心愉悦，幸福绵长',
				'怀揣梦想，心中充满希望',
				'热爱朗读，奇妙的世界在心中',
				'心中有梦，生活充满希望',
				'内心深处，是一片安宁的海洋',
				'心中充满爱意，生命充满光芒',
				'身心愉悦，幸福长存',
				'我怀抱着追寻梦想的心灵',
				'热爱朗读，充满激情与热爱',
				'心中有梦想，生活充满希望',
				'内心深处，宁静如水',
				'心中充满爱意，生命充满阳光',
				'身心愉悦，幸福长存',
				'我怀抱着追寻梦想的心'
			],

			// 记录下来当前的路有
			keeps: [],
			targetValue:"LINK"
		};
	},
	watch: {

		$route(to, from) {
			// 是否显示返回首页的气泡
			let bubbles = ["/", "home", "m1", "m2", "orderpay", "success"]
			if (bubbles.indexOf(to.name) > -1)
				this.bubble = true;
			else
				this.bubble = false;

			// 设置祝福语
			let welcome = ["debug", "qtgs"]
			if (welcome.indexOf(to.name) === -1) {
				this.setWelcome()
			}

			// 从 sounds 返回 writings 时清除 sounds 的缓存
			this.clearKeep('sounds', 'writings', from, to);

		},
	},
	created() {
		this.init()
	},
	mounted() {
		this.$nextTick((n) => {
			setTimeout(() => {
				// 页面加载和挂载完毕后执行
				this.$store.commit("setAppShow", true);
				this.setuid()
			}, 500);
		});
	},
	methods: {
		async setuid() {
			let back = await this.$api.my.user.back()
			localStorage.setItem('uid', back.uid);
			// 增加绑定关系
			if(this.$route.query.referrer_uid || this.$route.query.referrer){
				let data = {
					uid:this.$route.query.referrer_uid,
					referrer:this.$route.query.referrer,
					enter_type:this.$route.query.type,
					source:this.$route.query.source
				}
				let d = await this.$api.home.user.referrer(data)
				// console.log(data,"aaa")
			}
		},
		// 初始化页面
		async init() {
			await this.login()
			await this.setKeeps()
		},

		// 设置祝福语
		async setWelcome() {
			let isToken = await this.isLogin()
			if (!isToken) {
				// 打开祝福语
				// showDialog({
				// 	title: '🥳🥳🥳欢迎您回家🏠',
				// 	message: this.$tool.arr.randomOne(this.Blessings),
				// 	theme: 'round-button',
				// 	confirmButtonText: '立即回家',
				// }).then(() => {
				this.isLogin(true)
				// });
			}
		},

		// 检测是否登陆
		isLogin(login) {
			let token = this.$tool.storage.get("login-token");
			if (token) {
				return token;
			} else {
				login && this.$wechat.code(1);
				return;
			}
		},

		// 登陆
		async login() {
			//微信登录
			
			let loginToken = this.isLogin();
			let code = this.$tool.http.urlParse().code;
			let state = this.$tool.http.urlParse().state;
			if (code && (state == "wx-code" || state == "wx-code-info")) {
				// 没有token
				if (!loginToken) {

					// 尝试登陆
					let loginInfo = await this.$api.mine.join.loginSocial({
						code: code
					})

					// 检测没有账户-重新注册
					loginInfo.code == 100 && (() => {
						this.$wechat.code(1);
						return;
					})

					// 缓存token
					login.cacheToken(loginInfo);
					// 保存用户信息
					this.$api.mine.info.xMe().then((user) => {
						console.log(user);
						// 保存用户信息
						login.cacheInfo(user);

						// 保存项目用户信息
						this.$api.mine.info.xPMe().then((puser) => {
							login.cacheProjectInfo(puser);
							localStorage.setItem("uid", puser.uid);
							//登录后刷新一次页面 防止页面因为没有token数据不完整,同时用户最多只需点一次回家按钮 
							window.location.reload();
						});
					});



				}
				showToast("登录成功");
				//更新登录状态 
				this.init();
			}
		},

		// 设置需要缓存的页面
		setKeeps() {

			// 筛选出 meta.keep 为 true 的路由
			const keepRoutes = routes.filter(route => route.meta && route.meta.keep);

			// 创建包含这些路由 name 属性的数组
			this.keeps = keepRoutes.map(route => route.name);

		},

		// 清除缓存
		clearKeep(cleraName, tsName, from, to) {
			//1.如果不是从 Writings访问 Sounds
			//2.并且不是从works（详情） 访问时  清除缓存刷新接口数据 
			if (from.name !== tsName && from.name != 'works' && to.name != "works") {
				console.log('清除了')
				const index = this.keeps.indexOf(cleraName);
				if (index !== -1) {
					this.keeps.splice(index, 1);
				}
			}

			// 如果从 Sounds 返回 Writings
			if (from.name === cleraName && to.name === tsName) {
				const index = this.keeps.indexOf(cleraName);
				if (index !== -1) {
					this.keeps.splice(index, 1);
				}
			}
			// 如果从 Writings 去往 Sounds
			if (from.name === tsName && to.name === cleraName) {
				// 确保 Sounds 页面被缓存
				if (!this.keeps.includes(cleraName)) {
					this.keeps.push(cleraName);
				}
			}
		}

	},
};