import tool from '@/common/tool'
import store from '@/store'
import router from '@/router'

import api from '@/api'
const login = {
	// 设置登录缓存
	cacheToken(r) {
		tool.storage.set(
			'login-token',
			`${r.token_type} ${r.access_token}`
		)

		tool.storage.set('login-expires', r.expires_in)
		tool.storage.set(
			'login-current',
			tool.time.timestamp()
		)
	},
	// 存储用户信息
	cacheInfo(d) {
		tool.storage.set('user', d)
		console.log('存储用户信息' + d)
	},

	// 存储项目用户信息
	cacheProjectInfo(d) {
		tool.storage.set('project-user', d)
	},

	// 删除code
	removeCode() {
		tool.storage.get('wx-code') && (tool.storage.remove('wx-code'))
		tool.storage.get('wx-code-info') && (tool.storage.remove('wx-code-info'))
	},

	// 跳转
	to(redirect) {
		!redirect && (redirect = '/')
		let path = [
			router.app.$route.path,
			'/mine/reg',
			'/mine/login',
			'/mine/password'
		]
		// console.log(path);
		path.map(v => {
			redirect.indexOf(v) != -1 && (redirect = '/')
		})
		// console.log(redirect);
		// alert('to:'+redirect)
		// window.location.href = redirect
		window.location.replace(redirect);
	},
}
export default login
