<template>
	<div class="app">
		<router-view v-slot="{ Component }" v-if="!$route.meta.keepAlive">
			<keep-alive :include="keeps">
				<component :is="Component" :key="$route.name + $route.meta.savedPosition" v-if="$route.meta.keep" />
			</keep-alive>
			<component :is="Component" :key="$route.name" v-if="!$route.meta.keep" />
		</router-view>
		<!-- 底部导航 -->
		<tabsin v-if="$route.meta.istabs"></tabsin>
		<!-- 气泡音频播放 根据路由设置展示否-->
		<suspension v-if="$route.meta.isbubble" />
		<!-- 气泡-返回首页 -->
		<!-- <van-floating-bubble v-if="bubble" axis="xy" magnetic="x" v-model:offset="offset"
      @click="$router.push({ path: '/home' })" :gap="5">
      <template v-slot:default>
        <div class="custom-content flex_fd_column">
          <van-icon name="wap-home" />
          <span>首页</span>
        </div>
      </template>
    </van-floating-bubble> -->

	</div>
</template>
<script type="text/javascript" src="./app.js"></script>
<style src="./app.scss" lang="scss" scoped></style>