import http from "./basic";
import path from "../path";

const student = {
  //首页客服 
  service: d => http(`${path.course2}/invite_imgs/h5-index`, d),
  //个人中心联系客服
  Myservice: d => http(`${path.course2}/invite_imgs/h5-personal-center`, d),

  //作品分类详情
  enrollment: (id, d) => http(`${path.uc}/small_happiness_classes/${id}`, d),
  details: d => http(`${path.community}/sign/works/details`, d),
  //录音详情1
  readWrite: id => http(`${path.uc}/collect_small_happinesses/${id}`),
  //更新 
  updeWrite: (id, d) => http(`${path.uc}/collect_small_happinesses/${id}`, d, 'PATCH'),
  //更新   
  updeWriteStatus: (id, d) => http(`${path.uc}/collect_small_happinesses/${id}/is_open`, d, 'POST'),
  //作品集 
  writeList: d => http(`${path.uc}/collect_small_happinesses`, d),
  //提交合成录音
  postWrite: d => http(`${path.uc}/collect_small_happinesses`, d, 'POST'),
  //提交修改录音
  patchWrite: (id, d) => http(`${path.uc}/collect_small_happinesses/${id}`, d, 'PATCH'),

  //签到  
  signWrite: d => http(`${path.community}/sign`, d, 'POST', 0),
  //点评官
  comment: d => http(`${path.community}/words/comment/days/users`, d),
  //班级信息
  group: d => http(`${path.uc}/group/works/detail`, d),
  //打卡人
  signUsers: d => http(`${path.uc}/group/works/sign/users`, d),
  //点赞  
  setLike: d => http(`${path.community}/sq_likes/add`, d, 'POST'),
  setNoLike: d => http(`${path.community}/sq_likes/del`, d, 'POST'),
  //评论
  comments: d => http(`${path.community}/sq_comments`, d),
  addComments: d => http(`${path.community}/sq_comments`, d, 'POST'),
  delComments: (id, d) => http(`${path.community}/sq_comments/${id}`, d, 'delete'),

  //录音列表班级分类 
  navigate: d => http(`${path.uc}/group/works/navigate`, d),
  happinesses: d => http(`${path.uc}/collect_small_happinesses`, d),



  //表情分类  
  emoji: {
    class: id => http(`${path.uc}/emoticon_classes?type=emoji`),
    detail: id => http(`${path.uc}/emoticons?type=emoji&perPage=999&class_id=1`),
  },
  read: {
    mode: d => http(`${path.uc}/users/recording/mode`, d, 'POST'),
    style: d => http(`${path.uc}/users/recording/style`, d, 'POST'),
  }






};
export default student;
