import http from "./basic";
import path from "../path";

export default {
	home: {
		// 首页轮播
		swiplist: d => http(`${path.uc}/collect_small_happinesses`, d),
		// 首页课程
		curriculum: d => http(`${path.course2}/courses/recommend`, d),
		// 首页智慧之星
		star: d => http(`${path.course2}/courses`, d),
		// 首页冥想
		meditation: {
			// 首页列表
			list: d => http(`${path.course2}/meditation/home`, d),
			info: d => http(`${path.course2}/meditation/detail/${d}`),
			aulist: d => http(`${path.course2}/meditation/list/${d}`),
			addcollect: (d, data) => http(`${path.course2}/meditation/favorite/${d}`, data, 'post'),
			delcollect: (d, data) => http(`${path.course2}/meditation/favorite/${d}`, data, 'delete')
		},
		// 课程录播室
		course: {
			// 聊天时 发送消息
			post: (id, d) => http(`${path.imws}/rooms/${id}/comments`, d, 'post'),
			// 课程列表
			list: (id, d) => http(`${path.imws}/rooms/${id}/comments`, d),
			// 评论点赞
			courseaddlike: (d) => http(`${path.community}/sq_likes/add`, d,  'post'),
			coursedellike: (d) => http(`${path.community}/sq_likes/del`, d,  'post'),
			// 录播、直播
			// 添加收藏/点赞
			addlike: (romid,couid,data) => http(`${path.imws}/rooms/${romid}/comments/${couid}/likes`,data, 'post'),
			// 取消收藏/点赞
			dellike: (d) => http(`${path.community}/sq_likes/del`, d, 'post'),
			pllist: (d, name) => http(`${path.community}/sq_comments?db_table_name=${name}&table_id=${d}`),
			addcomments: (d) => http(`${path.community}/sq_comments`, d, 'post'),
		},
		// 课程 详情 课程列表 学员心得
		coureslist: {
			// 课程搜索
			courseList: d =>http(`${path.course2}/courses`,d ),
			coursehHistory: d=>http(`${path.course2}/history/search?page=1&perPage=10`,d ),
			// 文章搜索
			// 搜索文章历史 -朗读页
			searchlist: (name,d)=>http(`${path.uc}/collect_small_happinesses?name=${name}&sort_type=search`,d ),
			searchHistory: d=>http(`${path.report}/logs/uid_history/uc_search?page=1&perPage=10`,d ),
			// 课程详情
			star: d => http(`${path.course2}/courses/${d}`, ),
			// 心得评论列表
			list: (d) => http(
				`${path.community}/sq_comments?db_table_name=user_center.collect_small_happinesses&table_id=${d}`),
			addcomments: (d) => http(`${path.community}/sq_comments`, d, 'post'),
			courList: (d,data) => http(`${path.imws}/courses/${d}/rooms`,data ),
		}
	},
	// l朗读页
	read:{
		// 分类
		classes: d => http(`${path.uc}/works/classes/navigations?id=${d}`, ),
		// 子分类
		structures: d => http(`${path.uc}/works/classes/structures?id=${d}`, ),
		//作品集
		writeList: d => http(`${path.uc}/collect_small_happinesses?class_id=${d}`,),
		// 大家在读,精选推荐,我的关注
		recommend: d => http(`${path.uc}/collect_small_happinesses`,d),
		classList: d => http(`${path.uc}/collect_small_happinesses?class_id=${d}&sort_type=children&page=1&perPage=4`,),
		tabread: d => http(`${path.uc}/collect_small_happinesses/recommend`, d),
		// 有声打卡排行榜
		Ranking: d => http(`${path.community}/users/works/sign/ranks`, d),
		// 打卡详情
		Rankinginfo: d => http(`${path.community}/users/works/sign/details`, d),
		// 有声大使vip
		soundvipinfo: d => http(`${path.tt_common}/agent_rights`, d),
		// 进入录播时检查接口
		recorded : d => http(`${path.imws}/rooms/${d}/checks/payments`),
		// 录播课详情
		recorInfo:(courid,roomid) => http(`${path.imws}/courses/${courid}/rooms/${roomid}`),
	},
	guanzhu: {
		add: (d) => http(`${path.community}/sq_follows/add`, d, 'POST'),
		del: (d) => http(`${path.community}/sq_follows/remove_fans`, d, 'POST'),
	},
	user:{
		// 绑定推荐人
		referrer: d => http(`${path.user_center}/user_info`, d, "POST"),
		// wxappid
		wxappid: d => http(`${path.user_center}/js_sdk`, d, "POST"),
	}
}