<template>
	<!-- 悬浮音频播放 -->
	<div>
		<!-- 悬浮播放器 -->
		<van-floating-bubble class="custom-bubble " :class="{widthActive:isWidth,xleft:isX}" v-model:offset="offset"
			axis="xy" magnetic="x" :gap='0' @click="bubbClick(offset)" @offset-change="offsetChange(offset)">
			<div class="bubb_cont" :class="isX ? 'isXflex' : ''">
				<div class="img_box">
					<p class="back_img">
						<img :src="$store.state.allAudio.info.images.img1" alt="" />
					</p>
					<p class="play_box">
						<!-- 暂停 -->
						<img src="./image/suspend.png" alt="" v-if="!$store.state.allAudio.isPlay"
							@click.stop="suspendBtn()" />
						<!-- 播放 -->
						<img src="./image/play.png" alt="" v-if="$store.state.allAudio.isPlay"
							@click.stop="playBtnImg()" />
					</p>
				</div>
				<div class="bubb_cent" v-show="isWidth">
					<div>
						<!-- <van-progress :percentage="50" pivot-text="" /> -->
						<van-slider class="slider-line" v-model="$store.state.allAudio.currentTime" :min="0"
							:max="$store.state.allAudio.duration || 999" bar-height="4px" active-color="#C5A972"
							inactive-color="#EBEBEB" readonly @change="userChange">
							<template #button></template>
						</van-slider>
					</div>
					<div class="bubb_txt" :class="isX ? 'isXflex' : ''" @click="go">
						<p class="van-ellipsis">{{$store.state.allAudio.info.author}}</p>
						<p class="van-ellipsis">{{$store.state.allAudio.info.title}}</p>
					</div>
				</div>
				<div @click="closeBtn()" v-show="isWidth" style="margin-right: 10px;margin-top: 11px;">
					<van-icon name="cross" color="#6E6E6E" size="16" />
				</div>
			</div>
		</van-floating-bubble>
	</div>
</template>

<script>
	import {
		createApp
	} from 'vue';
	import {
		FloatingBubble
	} from 'vant';
	import {
		ref
	} from 'vue';
	import {
		Popup
	} from 'vant';
	import {
		Progress
	} from 'vant';
	import {
		Icon,
		Slider as vanSlider,
	} from 'vant';
	import {
		mapState
	} from 'vuex';
	export default {
		components: {
			[ref.name]: ref,
			[createApp.name]: createApp,
			[FloatingBubble.name]: FloatingBubble,
			[Popup.name]: Popup,
			[Progress.name]: Progress,
			[Icon.name]: Icon,
			[vanSlider.name]: vanSlider
		},
		setup() {
			const right = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
			const bottom = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
			const eenWidth = window.screen.width;
			const offset = ref({
				x: eenWidth - 50,
				y: bottom - 200
			});
			return {
				offset,
				right,
				bottom,
				eenWidth
			};
		},
		data() {
			return {
				isWidth: false,
				isX: false,
				isY: false,
				playTime: 0,
				duration: 100,
				shijian: 0,
				ispalyimg: false,
				playTimeT: 0,
				isquanju: false,
				screenWidth: 0
			}
		},
		computed: {
			...mapState({
				audiovux: state => state.audioVux.isPlay,
				audiovux1: state => state.audioVux1.isPlay,
				allAudio: state => state.allAudio.currentTime,
			})
		},
		watch: {
			audiovux(newValue, oldValue) {
				if (newValue == true) {
					this.$store.state.allAudio.isPlay = false
					this.$store.commit("audioAll", "pause");
				}
			},
			audiovux1(newValue, oldValue) {
				if (newValue == true) {
					this.$store.state.allAudio.isPlay = false
					this.$store.commit("audioAll", "pause");
				}
			},
			allAudio(newValue){
				if(this.$store.state.allAudio.currentTime == this.$store.state.allAudio.duration){
					this.isfalse()
				}
			}
		},
		mounted() {
			this.screenWidth = window.screen.width;
		},
		methods: {
			go() {
				// console.log(this.$store.state.allAudio.info.isgo)
				if (this.$store.state.allAudio.info.isgo) {
					this.$router.push({
						path: "/think",
						query: {
							id: this.$store.state.allAudio.info.id,
							type: this.$store.state.allAudio.info.type
						},
					})
				} else {
					console.log(":bu")
				}
				if(this.$store.state.allAudio.info.recorded){
					this.$router.push({
						path: '/study',
						query: {
							room_id: this.$store.state.allAudio.info.id, //直播间id
							couresId: this.$store.state.allAudio.info.couresId,
							title: this.$store.state.allAudio.info.author
						}
					})
				}
			},
			// 图片上播放按钮
			playBtnImg() {
				console.log(this.$store.state.allAudio)
				// 其他音频全部暂停
				// this.$store.state.audioVux.isPlay = false
				// this.$store.state.audioVux1.isPlay = false
				// this.$store.commit("audioNew", "pause");
				// this.$store.commit("audio", "pause");
				if (this.$store.state.allAudio.info.id) {
					this.$store.state.allAudio.isPlay = false
					// console.log("暂停", this.$store.state.allAudio.info.id)
					this.$store.commit("audioAll", "pause");
					// 暂停时收回
					// 点击×号调用this.isfalse()方法直接关闭浮动播放器
					this.isfalse()
				} else {
					// console.log(222)
					return;
				}

			},
			//暂停
			suspendBtn() {
				// console.log(this.$store.state.allAudio.info)
				if (this.$store.state.allAudio.info.id) {

					this.$store.state.allAudio.isPlay = true
					// console.log("播放", this.$store.state.allAudio.isPlay)
					this.$store.commit("audioAll", 'play')
					// 其他音频全部暂停
					this.$store.state.audioVux.isPlay = false
					this.$store.state.audioVux1.isPlay = false
					this.$store.commit("audioNew", "pause");
					this.$store.commit("audio", "pause");
				} else {
					// console.log(222)
					this.$store.commit("audio");
					return;
				}

			},
			// 播放条监听
			userChange(e) {
				this.playTime = e
				this.$store.state.allAudio.audio.currentTime = e;
			},
			// 浮动气泡点击
			bubbClick(e) {
				// console.log(this.$store.state.allAudio.info);
				// 判断在屏幕右侧的话会调整x坐标
				let num = 0
				// let num = this.screenWidth <= 400 ? 300 : 320
				if (this.screenWidth <= 400) {
					num = this.screenWidth - 100
				}
				if (this.screenWidth > 400) {
					num = this.screenWidth - 110
				}
				if (this.offset.x > 0) {
					if (this.isWidth = "false") { // eslint-disable-line
						// let num = this.right
						this.offset = ref({
							x: this.screenWidth - num,
							y: this.offset.y
						});
					}
					this.isWidth = true
				} else {
					this.isWidth = true
				}
			},

			// 气泡关闭按钮
			closeBtn() {
				// info 空的话气泡消失
				this.$store.state.allAudio.info = {}
				this.$store.state.allAudio.isPlay = false
				this.$store.commit("audioAll", "pause");
				this.isWidth = false
				let num = this.right
				this.offset = ref({
					x: this.isX ? 0 : this.right - 50,
					y: this.offset.y
				});
			},
			// 浮动气泡监听
			offsetChange(e) {
				// console.log(e)
				if (e.x == 0) {
					this.isWidth = false
					this.isX = true
				} else {
					this.isX = false
					this.isWidth = false
					this.offset = ref({
						x: this.isX ? 0 : this.right - 50,
						y: this.offset.y
					});
				}
			},
			isfalse() {
				this.isWidth = false
				let num = this.right
				this.offset = ref({
					x: this.isX ? 0 : this.right - 50,
					y: this.offset.y
				});
			},

		}
	}
</script>

<style lang="scss" scoped>
	// :global(.van-floating-bubble) {
	// 	--custom-top: 50px;
	// 	--custom-left: 100px;
	// 	top: var(--custom-top);
	// 	left: var(--custom-left);
	// }

	:global(.van-floating-bubble) {
		width: 50px !important;
		height: 50px !important;
		overflow: hidden !important;
		border-radius: 8px 0px 0px 8px !important;
		box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.3) !important;
		transition: 0.5s;
		background: white !important;
		// width: 20.625rem;
	}

	// .bubble-content {
	//   padding: 10px;
	//   background-color: #f3f4f6;
	//   border-radius: 5px;
	//   cursor: pointer;
	//   /* 使用 CSS 变量来控制 transform */
	//   transform: translateX(var(--bubble-translate-x, 0));
	// }


	:global(.xleft) {
		border-radius: 0px 8px 8px 0px !important;
	}

	:global(.widthActive) {
		width: 280px !important;
		border-radius: 8px !important;
	}

	.bubb_cont {
		width: 100%;
		height: 50px;
		padding: 8px 7px 8px 7.5px;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;

		.img_box {
			width: 35px;
			height: 35px;
			position: relative;

			.back_img {
				width: 35px;
				height: 35px;
				margin: 0;

				>img {
					width: 100%;
					height: 100%;
					border-radius: 5px;
				}
			}

			.play_box {
				width: 18px;
				height: 18px;
				position: absolute;
				top: calc(100% - 38px);
				left: calc(100% - 25px);

				img {
					width: 100%;
					height: 100%;
				}
			}
		}

		.bubb_cent {
			width: 196px;

			.bubb_txt {
				width: 100%;
				display: flex;
				justify-content: space-between;
				font-style: normal;
				font-size: 10px;
				color: #6E6E6E;
				margin-top: 10px;

				p {
					padding: 0;
					margin: 0;
				}
			}
		}
	}

	.isXflex {
		// 弹性盒模型属性调转排列方向
		flex-direction: row-reverse;
	}
</style>