import http from "./basic";
import path from "../path";

const common = {
  //主题色
  subject: d => http(`${path.report}/report/url_config/api_env`, d),
  userVisitLog: (params = {}) => http(`${path.report}/report/front_view_logs`, params, "post"),
  //活动配置
  huodo: d => http(`${path.tt_common}/activity/page/msy0000002`, d),
  //弹幕添加 uc/api/users/new_year/random_wish
  wish: {
    getWish: d => http(`${path.uc}/users/new_year/random_wish`, d),
    postWish: d => http(`${path.uc}/users/new_year/wish`, d, "post"),
  },
  //大转盘
  lottery: {
    get: d => http(`${path.uc}/lottery/new_year_2024`, d),
    post: (d) => http(`${path.uc}/lottery/new_year_2024`, d, 'post'),
  },
  //地址
  address: {
    areaList: d => http(`${path.tt_common}/area_city`, d),
    post: d => http(`${path.uc}/collect/lottery/address`, d, 'post'),
  },
  //机器人
  bot: {
    //微信机器人用户绑定系统用户
    post: d => http(`${path.uc}/bot/users/binding`, d, 'post'),
    //向机器人发送消息 
    send: (id, d) => http(`${path.uc}/users/works/${id}/experiences/send`, d, 'post'),

  }
};
export default common;
