
import http from "./basic";
import path from "../path";

export default {
	msg:{
		adminMsg: (id, d = {}) => http(`${path.imws}/rooms/${id}/messages`, d), //直播间消息
		nowRoomCourse: (id, d) => http(`${path.imws}/courses/${id}`, d), //当前直播间的相关咨商会信息
		send: (d, type = 0) => {
			let str = '';
			let roomId = d.room_id
			switch (type) {
				case 0:
					str = 'text';
					break; // 文字
				case 1:
					str = 'audio';
					break; //语音
				case 2:
					str = 'image';
					break; //图片
				case 3:
					str = 'tips';
					break; //提醒消息
				case 4:
					str = 'video';
					break; //提醒消息
				case 5:
					str = 'news';
					break; //图文消息
				case 6:
					str = 'link';
					break; //链接消息
				case 7:
					str = 'music';
					break; //链接消息
			}
			return http(`${path.imws}/rooms/${roomId}/${str}/messages`, d, 'POST')
		}
	},
	user:{
		checkAdmin: (id, d = {}) => http(`${path.imws}/rooms/${id}/checks/users`, d), //检查是否是管理员
		checkCol: (id, d = {}) => http(`${path.imws}/rooms/${id}/checks/collects`, d), //检查当前直播间是否收藏
		colOpera: (id, d = {}) => http(`${path.imws}/rooms/${id}/collects`, d, 'POST'), //收藏操作
		onlineUser: (id, d = {}) => http(`${path.imws}/rooms/${id}/online/users`, d),//收听列表
		// 直播间发言列表
		roomSpeakers: (id, d) => http(`${path.imws}/rooms/${id}/speakers`, d),
	},
	// 推荐列表
	recom:{
		// 推荐课程列表
		cour: (id, d = {}) => http(`${path.course2}/courses`, d), 
		forum: (id, d = {}) => http(`${path.forum}/articles`, d), 
	}
}
