import http from "./basic";
import path from "../path";

export default {

    act: {
        // 活动列表
        list: d => http(`${path.msy}/activities`, d),
        get: (id, d) => http(`${path.msy}/activities/${id}`, d),

        // 作品的活动消息
        getWork: (id, d) => http(`${path.msy}/activities/check?works_id=${id}`, d),
		// 朗读作品是否购买
		ispurchase: (id, workid) => http(`${path.uc}/check/works/Payments?class_id=${id}&works_id=${workid}`),
		iscoure:id => http(`${path.imws}/rooms/${id}/checks/payments`),
    },

    // 课程分类列表
    class: d => http(`${path.course2}/courses?class_id=70`, d),

    // 某个用户的录音列表
    userSounds: (uid, d) => http(`${path.uc}/collect_small_happinesses?class_id=0&sort_type=sound_uid&doc_id=>0&uid=${uid}`, d),




    /**
     * 我的作品
     * doc_id=>0是文案
     * own = 1 是自己
    */
    mySound: (own = 1, d) => http(`${path.uc}/collect_small_happinesses?class_id=0&doc_id=>0&own=${own}`, d),

    // 获取草稿
    draft: (own = 1, d) => http(`${path.uc}/collect_small_happinesses/auto_store`, d),

    // 我的投稿
    myWritings: (own = 1, d) => http(`${path.uc}/collect_small_happinesses?doc_id=0&own=${own}`, d),

    // 我的作品详情
    myWorkInfos: (id, d) => http(`${path.uc}/collect_small_happinesses/user/statistic?uid=${id}`, d),

    // 班级用户列表
    groupUsers: (id, d) => http(`${path.uc}/group/users?group_id=${id}`, d),

    // 作品分类导航
    // worksClass: (d) => http(`${path.uc}/works_class/navigate`, d),
    worksClass: (d) => http(`${path.uc}/works/classes/navigations?id=2`, d),

    // 推荐文案
    recommend: id => http(`${path.uc}/collect_small_happinesses/recommend?class_id=${id}`),

    writing: {
        get: id => http(`${path.uc}/collect_small_happinesses/${id}`),

    },
    /** 
    * 
    ************  妙 1 期  ***********
    */
    m1: {
        summary: {
            //报告
            get: (d) => http(`${path.report}/summary/msy1`, d),
            //海报
            poster: (d) => http(`${path.report}/summary/msy1/poster`, d, 'post'),
            //奖金 
            balance: (d) => http(`${path.report}/summary/msy1/balance`, d, 'post'),
        },
    },
    /** 
     * 
     ************  妙 2 期  ***********
     */
    m2: {
        // 明星学员
        star: d => http(`${path.uc}/group/works/users`, d),

        // 头像，作品数量
        users: d => http(`${path.uc}/project_users`, d),

        // 卡片列表
        cards: d => http(`${path.uc}/collect/cards?channel=msy2`, d),

        // 卡片详情
        cardDetail: (id, d) => http(`${path.uc}/collect/cards/${id}`, d),

        //合成卡片
        postCard: (id, d) => http(`${path.uc}/collect/synthesized/${id}`, d, 'post'),

        // 班级完成集卡的人
        complete: (id, d) => http(`${path.uc}/collect/cards/complete/users?channel=msy2`, d),

        // 赠送卡位
        giveCard: (id, d) => http(`${path.uc}/collect/cards/${id}/give`, d, "post"),

        // 大转盘助力页面详情
        help: (id, uid, d) => http(`${path.uc}/collect/cards/${id}/help?help_uid=${uid}`, d),

        // 大转盘助力页面 点击助力抽奖
        postHelp: (id, uid, d) => http(`${path.uc}/collect/cards/${id}/help?help_uid=${uid}`, d, 'post'),

        // 查看获取到的内容
        getTasks: (id, d) => http(`${path.uc}/user/async/tasks/${id}`, d),

        // 二期结业
        report: {
            //字卡
            cards: d => http(`${path.uc}/users/synthesized/cards?channel=msy2`, d),
            //海报
            poster: (d) => http(`${path.report}/summary/msy2/poster`, d, 'post'),
            summary: d => http(`${path.report}/summary/msy2`, d),
            //红包
            packet: (d) => http(`${path.report}/summary/msy2/red_packet`, d, 'post'),
            //领取优惠券
            coupon: (d) => http(`${path.report}/summary/msy2/coupon`, d, 'post'),
            //大转盘
            lottery: {
                get: d => http(`${path.uc}/lottery/msy2`, d),
                post: (d) => http(`${path.uc}/lottery/msy2`, d, 'post'),
            }
        }
    },
    // 妙三期
    m3: {
        activities: (id, d) => http(`${path.tt_common}/msy/activities/${id}`, d),
        //每天的文案 教学
        copywriting: {
            get: d => http(`${path.uc}/collect_small_happinesses/today`, d),
            //每天的文案 教学 教学收听（POST）  class_id
            post: (id, d) => http(`${path.uc}/collect_small_happinesses/teaching_listen_log/${id}`, d, 'post'),
        },
        //心声
        experiences: {
            //id 文案的id
            get: (id, d) => http(`${path.uc}/users/works/${id}/experiences`, d),
            post: (id, d) => http(`${path.uc}/users/works/${id}/experiences`, d, 'post'),
        },
        // 三期结业
        report: {
            //是否完成结业
            over: d => http(`${path.uc}/check_works_completion`, d),
            //海报
            poster: (d) => http(`${path.report}/summary/msy3/poster`, d, 'post'),
			
            summary: d => http(`${path.report}/summary/msy3`, d),
			
            //红包
            packet: (d) => http(`${path.report}/summary/msy3/red_packet`, d, 'post'),
            //心得状态开关
            switch: (d) => http(`${path.uc}/users_works_experience_status`, d, 'post'),
            //心得列表
            list: d => http(`${path.uc}/users/works/experiences`, d),
            //领取奖品
            prize: (d) => http(`${path.report}/summary/msy3/receive_prize`, d, 'post'),
			
            //赠送优惠券
            coupon: (d) => http(`${path.report}/summary/msy3/give_coupon`, d, 'post'),
        }
    },
	m4:{
		report:{
			// 海报
			poster: (num,d) => http(`${path.report}/summary/msy${num}/poster`, d, 'post'),
			summary: (num,d) => http(`${path.report}/summary/msy${num}`, d),
			// 领取奖品
			prize: (num,d) => http(`${path.report}/summary/msy${num}/receive_prize`, d, 'post'),
			// 赠送优惠卷
			coupon: (num,d) => http(`${path.report}/summary/msy${num}/give_coupon`, d, 'post'),
		}
	}
};

