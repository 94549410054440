/** 全局配置文件 */
import tool from "@/common/tool/index.js";
let pid, app_id, api, idchar, password, from_uid, ws_api;
// console.log(tool)
// let user = tool.storage.get("user");
// user && (from_uid = user.id || "");

if (window.location.host == "m.xs21.cn") {
  pid = 1;
  app_id = 2;
  // api = `https://api.xinghuixuetang.com`;
  api = `https://api-dev.xs22.cn`;
  // ws_api = ``;
  idchar = "";
  password = "";
} else {
  pid = 1;
  app_id = 2;
  // api = `https://api.xinghuishengyuan.com`;
  api = `https://api-dev.xs22.cn`;

  idchar = "";
  password = "myteam521";
}

export default {
  pid,
  app_id,
  api,
  idchar,
  password,
  from_uid,
  ws_api
};
