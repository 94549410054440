import axios from "axios";
import tool from "@/common/tool";
import wx from "@/common/tool/wx";
import env from "@/common/config";
import { showLoadingToast, closeToast, showFailToast, allowMultipleToast, showToast } from 'vant';
import store from "@/store";
import qs from "qs";
import path from "../path";
// import store from "@/store/index";
// console.log("env: ", env);

// 请求域名 从env配置中读取 信息
axios.defaults.baseURL = env.api;
// 请求超时 从env配置中读取 信息
axios.defaults.timeout = 300000;
let count = 0;
let loading = null;
const setting = {
  // 登录路径
  login: "/home"
};
let loadingToast = showLoadingToast({
  message: '加载中...',
  forbidClick: true,
  duration: 0,
})
loadingToast.close();
const hideLoading = () => {
  count--;
  count <= 0 && loadingToast.close();
};
const showLoading = () => {
  count++;
  if (count) {
    setTimeout(() => {
      allowMultipleToast();
    }, 3000);
  }
  // 设置loading 超时 未关闭 时间 
  setTimeout(() => {
    loadingToast.close();
  }, 30000);
};

// 返回值解析
axios.interceptors.response.use(
  config => {
    hideLoading();
    if (config.headers["event-id"] != undefined && config.headers["event-key"] != undefined) {
      tool.storage.set("headerInfo", config.headers);
      // store.commit("SET_SHOW_CARD_POP_SHOW", true);

    }
    return config;
  },
  error => {
    hideLoading();
    let err = error.response;
    let message = "";

    if (err && err.data) {
      if (err.data.errors) {
        Object.keys(err.data.errors).map((key, index) => {
          if (index == 0) {
            // 存在40029 重新获取code 
            message = err.data.errors[key][0];
          }
        });
      } else {
        message = err.data.message;
      }
    }

    if (message.indexOf("40029") > -1 || message.indexOf("40163") > -1) {
      wx.code();
      return false;
    }
    // 判断错误信息
    if (error.message.includes("timeout")) {
      showToast('网络超时');

    } else if (err.status) {
      // 判断错误状态
      switch (err.status) {
        case 401:
          // eslint-disable-next-line no-case-declarations
          let txt,
            updateToken = false,
            toLogin = true;
          // 1未登录，2Token类型错误，3过期，4其他设备登录
          switch (err.data.code) {
            case 1:
              txt = "请先登录";
              toLogin = false
              break;
            case 2:
              txt = "请先登录（Token类型错误）";
              break;
            case 3:
              txt = "登录过期，正在为您重新登录";
              toLogin = false;
              updateToken = true;
              break;
            case 4:
              txt = "您的账号已在其他账号登录";
              toLogin = false;
              updateToken = true;

              break;
            case 6:
              txt = message;
              toLogin = false;
              break;
            default:
              showToast(message);

          }
          // 13683146772
          tool.storage.remove("login-token");
          tool.storage.remove("login-current");
          tool.storage.remove("shop-current");
          tool.storage.remove("user");
          tool.storage.remove("project-user");
          txt && showToast(txt);


          // 更新token
          if (updateToken) {
            let option = {
              method: "PUT",
              url: `${path.user_center}/authorizations/current`,
              headers: {
                "content-type": 'application/x-www-form-urlencoded'
              }
            };
            let token = tool.storage.get("login-token");
            //解决Bearer丢失问题
            if (token && !token.startsWith("Bearer ")) {
              token = "Bearer ".concat(token);
            }
            option.headers.Authorization = token;

            option.data = qs.stringify({
              pid: env.pid,
              app_id: env.app_id
            });
            axios(option).then(r => {
              tool.storage.set("login-token", `${r.token_type} ${r.access_token}`);
              tool.storage.set("login-expires", r.expires_in);
              tool.storage.set("login-current", tool.time.timestamp());
            });
          }
          if (toLogin) {
            setTimeout(() => {
              let { redirect } = tool.http.urlParse();
              window.location.replace(
                `${window.location.origin}${setting.login}/?redirect=${redirect ||
                window.location.href}`)
            }, 2000);
          }
          break;
        // 404请求不存在
        case 404:
          break;
        // 其他错误，直接抛出错误提示
        default:
          //不弹出错误提示框
          if (err.data.code == 11) return;
          //弹出其他提示框
          if (err.data.code == 422 || err.data.status_code == 422) {
            showToast(message);
            return;
          }
          showToast(message);
      }
    }
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
// 封装axios请求接口
const http = async (url = "", d = {}, type = "GET", fromData = true, jdu = 0) => {

  type = type.toUpperCase();
  if (type === "PATCH") { fromData = false }
  //从env配置中读取 信息
  d.pid = d.pid || env.pid;
  d.app_id = d.app_id || env.app_id;
  d.page_path = window.location.href;
  // 如果是分享页面，则需要传递后端当前用户id_chart
  let { isShare } = tool.http.urlParse();

  parseInt(isShare) == 1 && (d.from_uid = d.from_uid || env.from_uid);

  let option = {
    method: type,
    url: url,
    // 设置header
    headers: {
      // 如需要此行生效 需要把 fromData设置为 false 默认为true
      "content-type": 'application/x-www-form-urlencoded'
    }
  };
  //oss上传进度
  if (jdu) {
    option = {
      method: type,
      url: url,
      // 设置header
      headers: {
        // 如需要此行生效 需要把 fromData设置为 false 默认为true
        "content-type": 'application/x-www-form-urlencoded'
      },
      onUploadProgress: function (progressEvent) {
        if (progressEvent.event.lengthComputable) {
          store.commit("setProgress", (progressEvent.loaded / progressEvent.total) * 100 || 0);
          // console.log('progressEvent', (progressEvent.loaded / progressEvent.total) * 100 || 0);
        }
      },
    };
  }


  // token
  let token = tool.storage.get("login-token");



  //解决Bearer丢失问题
  if (token && !token.startsWith("Bearer ")) {
    token = "Bearer ".concat(token);
  }
  token && (option.headers.Authorization = token);

  if (type === "POST" || type === "PUT" || type === "PATCH") {
    if (fromData) {
      let data = new FormData();
      Object.keys(d).forEach(key => {
        data.append(key, d[key]);
      });
      option.data = data;
    } else {
      option.data = qs.stringify(d);
    }
  } else {
    option.params = d;
  }
  // 更换token
  // await setting.overtime();
  return new Promise((resolve, reject) => {
    let key = `api_${url}?${qs.stringify(d)}`;
    tool.storage.remove(key); // 删除旧的key 新的key增加前缀 方便清除数据  
    !d.noLoading && showLoading();
    axios(option)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export default http;
