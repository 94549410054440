import { createApp } from 'vue'
import App from './App.vue'
import token from './api/apis/token'
import login from '@/common/login'
import { Icon, TextEllipsis, Empty, Lazyload } from 'vant';
// 抽奖
import VueLuckyCanvas from '@lucky-canvas/vue'
// 重构路由
import router from './router'
// 重构状态
import store from './store'
// 引入全局UI库  
import 'vant/lib/index.css';
import './style.scss'
// 移动端自适应 px => rem   
import "amfe-flexible";

import 'lib-flexible/flexible.js'
// 封装请求
import qs from "qs";
import tool from "./common/tool";
import api from "./api/index";
import wechat from './common/tool/wx'
import oss from './common/tool/oss'
import tc from './common/tool/tc'

import VueAwesomeSwiper from 'vue-awesome-swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';
// 设置全局属性
const app = createApp(App);
SwiperCore.use([Navigation, Pagination]);
app.use(Icon).use(TextEllipsis).use(Empty).use(VueLuckyCanvas);
app.use(VueAwesomeSwiper, /* { default options with global component } */);
app.config.globalProperties.$tool = tool;
app.config.globalProperties.$qs = qs;
app.config.globalProperties.$api = api;
app.config.globalProperties.$wechat = wechat;
app.config.globalProperties.$oss = oss;
app.config.globalProperties.$tc = tc;

// 全局分享
const share = (title = false, desc = false, img = false, link = false) => {
    wechat.share({
        title: title || '有声传奇',
        desc: desc || "我们的约定：用心发声，用爱陪伴，绽放自己，找到真我",
        images: img || "https://youshengchuanqi.oss-cn-shanghai.aliyuncs.com/picture/admin/system/resource/material/backend_1702639401699.png",
        link: link || wechat.link("info", "LINK")
    });
}

app.config.globalProperties.$share = share;


//图片懒加载
app.use(Lazyload, {
    perload: 0,
    error: "", //图片加载失败时使用的图片源
    loading: "https://youshengchuanqi.oss-cn-shanghai.aliyuncs.com/ui/loading/loading-1.png", //图片加载的路径
    attempt: 3 //尝试加载次数
});


// 用户登录 同步
const userLogin = () => {
    new Promise((r, j) => {
        //检测是否有token
        let obj = tool.http.urlParse();
        let url = tool.http.urlDel(window.location.href, "code");
        url = tool.http.urlDel(url, "state");
        var debugArr = [
            "debug/team",
            "/home",
            "/",
			"/newhome"
        ];
        var index = debugArr.findIndex(v => window.location.href.indexOf(v) > -1);
        let loginToken = tool.storage.get("login-token");
        if (!loginToken) {
            tool.storage.set("code", obj.code);
        } else {
            let puser = tool.storage.get("project-user", "");
            // if (puser) {
            //     if (!puser.binds.wechat) {
            //         tool.storage.set("bindsWxCode", obj.code);
            //     }
            // }
            tool.storage.remove("code");
        }
        if (index > -1) return r();

        let { code } = obj;
        if (!loginToken && code) {
            api.mine.join.loginSocial({
                code: code
            }).then(res => {
                res.token_type && login.cacheToken(res);
                let token = tool.storage.get("login-token");
            })

        }
        if (loginToken) {
            // 保存用户信息
            api.mine.info.xMe().then((user) => {
                login.cacheInfo(user);
                // 保存项目用户信息
                api.mine.info.xPMe().then((puser) => {
                    login.cacheProjectInfo(puser);
                });
            });
        }
    })
}
// 取消页面上的 "Uncaught runtime errors" 提示
app.config.errorHandler = (err, vm, info) => {
    // 自定义处理错误的逻辑，这里可以不做任何操作或者进行其他的错误处理
};


(async () => {
    try {
        await userLogin();
    } catch (e) {
        console.error("无法访问", e);
    }
    // 挂载应用程序
    app.use(store).use(router);
    const vm = app.mount('#app');

    // 每5秒检查是否到期 
    token.overtime();
    setInterval(() => {
        token.overtime();
    }, 15000);
})();

let page_path_referer = "";
router.beforeEach((to, from, next) => {
    page_path_referer = from.fullPath;
    next();
});
router.afterEach((to, from) => {
    let from1 = tool.storage.get("from1", "");
    from = tool.storage.get("from", from1);
    let isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isIos && !window.wechatShareUrl) {
        window.wechatShareUrl = `${window.location.origin}${to.fullPath}`;
    }

    setTimeout(() => {
        api.common.userVisitLog({
            from: from,
            path: to.fullPath,
            title: document.title,
            path_referer: page_path_referer
        });
    }, 1000);
});

