import tool from "@/common/tool";

const d_uc = tool.storage.get("debug-path-uc");
const d_user_center = tool.storage.get("debug-path-user_center");
const d_wechat = tool.storage.get("debug-path-wechat");
const d_common = tool.storage.get("debug-path-common");
const d_tt_common = tool.storage.get("debug-path-tt_common");
const d_forum = tool.storage.get("debug-path-forum");
const d_forum2 = tool.storage.get("debug-path-forum2");
const d_course = tool.storage.get("debug-path-course");
const d_course2 = tool.storage.get("debug-path-course2");
const d_live = tool.storage.get("debug-path-live");
const d_shop = tool.storage.get("debug-path-shop");
const d_survey = tool.storage.get("survey");
const d_report = tool.storage.get("report");

export default {
  uc: d_uc || "/uc/api",
  user_center: d_user_center || "/user_center/api",
  wechat: d_wechat || "/wechat/api",
  common: d_common || "/common/api",
  tt_common: d_tt_common || "/tt_common/api",
  forum: d_forum || "/forum/api",
  forum2: d_forum2 || "/forum2/api",
  course: d_course || "/course/api",
  course2: d_course2 || "/course2/api",
  live: d_live || "/live/api",
  shop: d_shop || "/gem/api",
  survey: d_survey || "/uc/api",
  report: d_report || "/report/api",
  message: "/message_push/api",
  community: "/community/api",
  video_number_shop: "/video_number_shop/api",
  chat: "/chat/api",
  shop2: "/shop/api",
  imws: "/imws/api",
  msy: "/tt_common/api/msy",
};
