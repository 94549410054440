import http from "./basic";
import path from "../path";

export default {
  address: {
    // 获取用户的默认地址
    default: d => http(`${path.tt_common}/user_addresses/default_add`, d),
    // 获取用户的收获地址列表
    list: d => http(`${path.tt_common}/user_addresses`, d),
    // 获取用户的收获地址列表
    getId: id => http(`${path.tt_common}/user_addresses/${id}`),
    // 添加用户收获地址
    add: d => http(`${path.tt_common}/user_addresses`, d, "post"),
    // 更新用户地址
    edit: (id, d) => http(`${path.tt_common}/user_addresses/${id}`, d, "PATCH"),
    // 删除用户地址
    del: id => http(`${path.tt_common}/user_addresses/${id}`, {}, "DELETE"),
    // 城市列表(返回所有值)
    areas: d => http(`${path.tt_common}/area_city`, d),
    tst: () => http(`${path.user_center}/authorizations/current`, {}, "DELETE"),
    // 城市列表(返回所有值)
    async areas_format(d) {
      return new Promise(resolve => {
        http(`${path.tt_common}/area_city`, d).then(r => {
          let area = [];
          r.map((v, k) => {
            if (v.parent_id === 0) {
              area.push({
                value: v.id,
                text: v.name,
                parent_id: v.parent_id,
                id: v.id,
                children: []
              });
            }
          });

          r.map((v, k) => {
            area.map((v1, k1) => {
              // console.log(v1)
              if (v1.id == v.parent_id) {
                area[k1].children.push({
                  value: v.id,
                  text: v.name,
                  parent_id: v.parent_id,
                  id: v.id,
                  children: []
                });
              }
            });
          });

          r.map((v, k) => {
            area.map((v1, k1) => {
              area[k1].children.map((v2, k2) => {
                if (v2.id == v.parent_id) {
                  area[k1].children[k2].children.push({
                    value: v.id,
                    parent_id: v.parent_id,
                    id: v.id,
                    text: v.name
                  });
                }
              });
            });
          });
          resolve(area);
        });
      });
    }
  },
  // 快递
  express: {
    list: () => http(`${path.tt_common}/expresses`)
  },
  share: {
    card: {
      // 获取名片模板列表
      list: d => http(`${path.forum}/cards/cate/${d}`),

      // 获取推荐名片模板列表
      recommend: () => http(`${path.forum}/cards/recommend`),

      // 生成名片
      machine: (id, d = {}) => http(`${path.forum}/cards/setCard/${id}`, d),

      // 生成名片数量
      nums: () => http(`${path.forum}/cards/cards_count`),
      // 生成名片数量
      commission: () => http(`${path.forum}/cards/universal_promotion`)
    }
  },
  app: {
    // 收音机
    info: (id, d = {}) => http(`${path.uc}/collect_small_happinesses/${id}`, d),
    list: (d = {}) => http(`${path.uc}/collect_small_happinesses`, d),
    // 点赞收音机
    likes: (type, d) => http(`${path.community}/sq_likes/${type}`, d, "post"),
    navigations: (d = {}) => http(`${path.community}/navigations`, d),
    happy_linsten_logs: (id, d = {}) =>
      http(`${path.uc}/collect_small_happinesses/listen_log/${id}`, d, "POST")
  },
  oss: {
    // 获取证书
    loadPolicy: (d, purpose = "") =>
      http(`${path.user_center}/material_policy?type=${d}&purpose=${purpose}`),

    // 写入素材库
    uploadMaterials: d => http(`${path.user_center}/materials`, d, "post"),

    // 上传至oss
    uploadOss: (url, d) => http(url, d, "post", 1, 1),
    //删除资源
    del: url => http(`${path.user_center}/materials/delete?url=${url}`, {}, "DELETE"),

    // 开始上传
    async upload(file, type = 1, purpose, hz, meta) {
      // 1图片 2pdf 3doc 4xls 5音频 6视频 7压缩文件 9其他
      let style = ["", "picture", "pdf", "doc", "xls", "audio", "video", "zip", ""];
      let t = style[type];
      let r = await this.loadPolicy(t, purpose);

      let name = r.dir + Date.now() + parseInt(Math.random() * 1000000000);
      hz && (name = name + hz)
      let data = {
        key: name,
        name,
        policy: r.policy,
        OSSAccessKeyId: r.accessid,
        expire: r.expire,
        signature: r.signature,
        success_action_status: "200",
        file
      };

      await this.uploadOss(r.host, data);

      data = {
        // 上传的路径（业务名称/文件类型，比如 live/audio），长度：3~100
        // 如果有upload ，则为目录的路径，如果是OSS已上传的素材，则是带文件名的素材路径
        path: name,
        type: type,

      };
      meta && (data.meta = meta)
      r = await this.uploadMaterials(data);
      // return r.url
      return Promise.resolve(r.url);
    },
    getWechatVoice: d => http(`${path.message}/js_sdk_file`, d, "post")
  },
  emoji: () => http(`${path.forum}/emoji`)
};
