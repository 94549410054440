import http from "./basic";
import path from "../path";

const mine = {
	//虚拟用户 
	virtualUser: d => http(`${path.uc}/virtual/users`, d),
	wechat: {
		// 获取微信的jssdk配置信息
		jssdk: u => http(`${path.user_center}/js_sdk`, {
			url: u,
			js_api_list: [
				"onMenuShareAppMessage",
				"onMenuShareTimeline",
				"onMenuShareQQ",
				"onMenuShareWeibo",
				"updateAppMessageShareData",
				"updateTimelineShareData",
				"scanQRCode",
				"openLocation",
				"chooseLocation",
				"getLocation",
				"startRecord",
				"stopRecord",
				"onVoiceRecordEnd",
				"playVoice",
				"pauseVoice",
				"stopVoice",
				"onVoicePlayEnd",
				"uploadVoice"
			]
		})
	},
	
	join: {
		// 国家选择
		smsArea: d => http(`${path.user_center}/sms_internationals`, d),
		// 短信验证码
		verificationCodes: d => http(`${path.user_center}/verificationCodes`, d, "POST"),
		// 获取图片验证码
		captchas: d => http(`${path.user_center}/captchas`, d, "POST"),
		// 客户端登录
		login: d => http(`${path.user_center}/authorizations`, d, "POST"),
		// 第三方登录
		loginSocial: d => http(`${path.user_center}/socials/weixin/authorizations`, d, "POST"),
		//注册
		reg: d => http(`${path.user_center}/users`, d, "POST"),
		// 修改手机号码
		setMobile: d => http(`${path.user_center}/mobile`, d, "PATCH", false),
		// debug 用户登录
		debugLogin: d => http(`${path.user_center}/authorizations/sup_login`, d, "POST"),
		// 新用户 open_id绑定手机
		createUser: d => http(`${path.user_center}/authorizations/new_user_login`, d, "POST")
	},
	info: {
		// 重置密码
		password: d => http(`${path.user_center}/password`, d, "PATCH", false),
		// 获取当前用户信息
		me: d => http(`${path.user_center}/me`, d),
		// 指定用户信息 id 为uid
		user: d => http(`${path.user_center}/users/${d}`, {}),
		// 绑定推荐人
		referrer: d => http(`${path.user_center}/user_info`, d, "POST"),
		// 协议
		agreement: d => http(`${path.user_center}/project_agreements/${d}`, {}),
		// 获取某用户头像和昵称（不用认证）
		xUserRef: d => http(`${path.uc}/users/limit/${d}`, {}),
		// 当前用户的项目用户详情 columns要显示的字段列表，以逗号分隔
		xPMe: d => http(`${path.uc}/project_users/current`, d),
		// 当前用户详情 columns要显示的字段列表，以逗号分隔
		xMe: d => http(`${path.uc}/users/current`, d),
		updata: d => http(`${path.user_center}/user_info`, d, "PATCH", false),
		// 获取用户列表
		searchUserInfo: d => http(`${path.user_center}/users`, d),
		//奖励金
		grant_notice: d => http(`${path.user_center}/grant_notice`, d)
	},
	tags: {
		list: d => http(`${path.uc}/tags`, d)
	},
	user: {
		audio: d => http(`${path.message}/wechat/media/audio`, d, "POST"),
		//更新用户手机号 
		setMobile: (d) => http(`${path.user_center}/mobile`, d, "PATCH"),
		//更新用户信息
		setUser: (d) => http(`${path.user_center}/user`, d, "PATCH"),

		aboutUser: d => http(`${path.report}/uc/project_users/about_user`, d),
		// 当前用户信息
		info: d => http(`${path.user_center}/me`, d),
		setting: (prefix, d) => http(`${path.uc}/project_users/setting/${prefix}`, d, "POST"),
		// 其他用户信息
		otherUser: id => http(`${path.user_center}/users/${id}`),
		// 用户中心标签
		tags: id => http(`${path.user_center}/user_tag_types/${id}`),
		tagList: d => http(`${path.user_center}/user_tag_types`, d),
		// 已购买陪读数量
		courseNum: d => http(`${path.tt_common}/user_center`, d),
		noteTipsNum: d => http(`${path.forum}/course_note/count/unread`, d),
		// 学员列表及学员分类列表
		student: d => http(`${path.report}/uc/project_users/referrer`, d),
		// 个人二维码
		qrCode: d => http(`${path.user_center}/qr_codes`, d, "POST"),
		// 兴趣爱好
		interests: d => http(`${path.user_center}/interests`),
		// 上级
		referrer: d => http(`${path.uc}/project_users`, d),
		// 绑定上级 id_chart
		referrerIdChar: d => http(`${path.user_center}/user_referrer`, d, "PATCH", false),
		// 获取用户访问日志统/user_center/api/access_logs
		// visitCount: (d) => http(`${path.user_center}/access_logs`, d, 'POST'),
		//绑定微信
		bindWechat: d => http(`${path.user_center}/user`, d, "PATCH", false),
		//手机号码绑定微信
		bindWechatP: d => http(`${path.user_center}/binds/wechat`, d, "POST"),

		//群发消息
		messageAdd: d => http(`${path.message}/mass_messages`, d, "POST"),
		//群发消息列表
		messageList: d => http(`${path.message}/mass_messages`, d),
		//客服群发消息列表
		massChecks: d => http(`${path.message}/mass_checks`, d),
		//群发消息 审核
		messageAudit: (d, id) => http(`${path.message}/mass_checks/${id}`, d, "PATCH"),
		//群发消息 详情
		messageDetail: id => http(`${path.message}/mass_messages/${id}`),
		//体验卡
		ct202111: (d = {}) => http(`${path.report}/report/url_config/ct202111`, d),
		//绑定视频号
		bindVideoWx: d => http(`${path.video_number_shop}/user_apply_promoters`, d, "POST"),
		getVideoWx: d => http(`${path.video_number_shop}/user_apply_promoters`, d)
	},
	// 用户消息
	msg: {
		// 个人中心未读消息
		tipsHint: d => http(`${path.wechat}/infoma_tip`, d),
		// 个人中心未读消息数量
		tipsHintCount: d => http(`${path.wechat}/unread_count`, d),
		// 消息列表
		list: d => http(`${path.wechat}/informa`, d),
		// 消息详情
		details: (id, d) => http(`${path.wechat}/informa/${id}`, d),
		// 系统消息
		systems: d => http(`${path.wechat}/informa_sys`, d),
		//订单消息
		orderViews: () => http(`${path.tt_common}/order_views`),
		//查看未读订单
		readOrderViews: d => http(`${path.tt_common}/order_views/${d.couse}/${d.couse_id}`, d, "POST")
	},
	coupon: {
		list: d => http(`${path.common}/user_coupons`, d),
		take_coupons: d => http(`${path.report}/act/2023/10/take_coupons`, d, "POST"),
		// 兑换
		exchange: d => http(`${path.tt_common}/user_swaps`, d, "POST"),
		agent: {
			list: d => http(`${path.tt_common}/activity/receive_coupon`, d),
			receive: d => http(`${path.tt_common}/activity/receive_coupon`, d, "POST")
		}
	},

	// 虚拟币
	virtual: {
		// 详情
		details: d => http(`${path.user_center}/my_currencies`, d),
		// 类型
		type: d => http(`${path.user_center}/currencies`, d),
		// 账单列表
		bill: d => http(`${path.user_center}/currency_logs`, d),
		// 日统计
		dayDetails: d => http(`${path.user_center}/my_currency_daily`, d),
		// 月统计
		MonthDetails: d => http(`${path.uc}/user_summary/comm_monthly`, d),

		// 分佣详情
		// 新分佣详情	{{url}}/tt_common/api/bonuses/9803?pid=1&app_id=7&channel=common
		shareDetails: (id, d) => http(`${path.tt_common}/bonuses/${id}`, d),
		// 查询分佣订单id
		shareOrder: (id, d) => http(`${path.tt_common}/currency_history/${id}`, d),

		/* 佣金提现 计算税金等 */
		watchPrice: d => http(`${path.user_center}/cash_info`, d),
		// 申请提现
		withdraw: d => http(`${path.user_center}/cash`, d, "POST"),
		// 提现记录
		extractList: d => http(`${path.user_center}/cash`, d)
	},
	// 代理商
	agency: {
		// 会员套餐
		meals: d => http(`${path.common}/virtual_goods?sku=AGENT_UPGRADE`, d),
		// 生成订单
		mealsOrder: d => http(`${path.common}/virtual_good_orders`, d, "POST"),
		// 每日佣金分析
		dayBrokerage: d => http(`${path.report}/course/bonuse`, d),
		// 每日招生人数
		hasStudent: d => http(`${path.report}/course/repeat`, d),
		// 代理商卡片
		cards: d => http(`${path.forum}/cards/exclusive`, d, "POST"),
		// 新代理商套餐
		newMeals: d => http(`${path.tt_common}/agent_rights`, d),
		// 新代理商套餐详情
		getMeals: (id, d) => http(`${path.tt_common}/agent_rights/${id}`, d),
		// 新代理商订单
		newMealsOrder: d => http(`${path.tt_common}/virtual_good_orders`, d, "POST")
	},

	// 协议
	protocol: {
		details: (id, d) => http(`${path.user_center}/project_agreements/${id}`, d)
	},
	// 身份认证
	certificates: {
		// 个人
		personal: {
			details: d => http(`${path.user_center}/personal_certification`, d), // 认证后详情
			certification: d => http(`${path.user_center}/personal_certifications/`, d, "POST"), // 提交认证
			// edit: (d) => http(`${path.user_center}/personal_certification`, d, 'PATCH', false), // 编辑
			edit: d => http(`${path.user_center}/personal_certification?_method=PATCH`, d, "POST", false)
		},

		// 企业
		enterprise: {
			details: d => http(`${path.user_center}/enterprise_certification`, d),
			certification: d => http(`${path.user_center}/enterprise_certifications/`, d, "POST"),
			// edit: (d) => http(`${path.user_center}/enterprise_certification`, d, 'PATCH', false),
			edit: d =>
				http(`${path.user_center}/enterprise_certification?_method=PATCH`, d, "POST", false)
		}
	},
	// 留言
	leaveWord: {
		// 列表 /chat/api/
		list: d => http(`${path.chat}/messages/list`, d),
		add: param => http(`${path.chat}/messages/create`, param, "POST"),
		clear: param => http(`${path.chat}/messages/unread/clear`, param, "POST")
	},

};
export default mine;