import http from "./basic";
import path from "../path";

const course = {

  share: d => http(`${path.forum}/cards/setCard/activity?activity_key=series_20220615`, d),
  //读书会
  courseReads: params => http(`${path.course2}/course_reads`, params),
  //陪读分类
  courseClass: params => http(`${path.course2}/course_classes`, params),
  // 陪读列表
  list: params => http(`${path.course2}/courses`, params),
  lists: params => http(`${path.course2}/courses`, params),
  //陪读二级分类列表
  courseClassList: () => http(`${path.report}/uc/project_users/filters/course_id`),
  //首页陪读推荐分类
  homeCourseClasses: () => http(`${path.course2}/course_classes?page_location=home_page_top`),


  // 课程详情
  info: (id, d) => http(`${path.course2}/courses/${id}`, d),

  history: () => http(`${path.course2}/history/search`),
  // 客服二维码
  customerService: d => http(`${path.uc}/wechat/scheme/detail`, d, "post"),
  // 根据bonus_id获取订单号
  findOrderSn: (id, d) => http(`${path.tt_common}/currency_history/${id}`, d),
  agencyClass: d => http(`${path.course2}/course_classes`, d),
  //咨商日程 获取指定时间内的陪读   在首页显示加上 &relation_key=home_page
  calendarCouse: d =>
    http(
      `${path.course2}/courses?start_time_from=${d.startTime}&start_time_to=${d.endTime}&state=*`
    ),
  //咨商日程筛选时间范围
  calendarDataCouse: () => http(`${path.course2}/courses/more/between`),
  //邀请人数
  peopleNumber: (id, num) => http(`${path.report}/course/${id}/source_students?num=${num}`),
  peopleNumberTicket: (id, num) =>
    http(`${path.report}/course/${id}/act_report.coupon_used?num=${num}`),
  //领取体验卡
  getExperience: (d = {}) => http(`${path.report}/uc/generating_links`, d, "post"),
  //显示体验卡
  experience: (d = {}) => http(`${path.report}/uc/generating_links`, d),
  address202111: d => http(`${path.report}/report/url_config/${d}`),
  //是否显示毕业证
  graduation: (d = {}) => http(`${path.report}/report/url_config/graduation`, d),
  //毕业证
  setGraduation: (d = {}) => http(`${path.forum}/cards/setGraduation/139`, d),
  //推广课程详情图
  popularize: (d = {}) => http(`${path.report}/common/setting`, d),
  classes: d => http(`${path.course2}/course_classes`, d),
  student: {
    //学员故事
    story: (d, source_id) => http(`${path.forum}/students_story?source_id=${source_id}`, d),
    //学员故事all
    storyAll: d => http(`${path.forum}/students_story`, d),
    //故事详情
    storyDetail: id => http(`${path.forum}/students_story/${id}`),
    //相关故事推荐
    recommendStory: () => http(`${path.forum}/students_story?recommend=1`),
    //学员故事分类
    relatedStory: () => http(`${path.forum}/categories/story`)
  },
  //弹框
  popUp: {
    //是否弹出直播间选择
    entry_points: d => http(`${path.live}/entry_points`, d, "post"),
    //进入直播间
    enter: (id, d) => http(`${path.live}/lizhi_room/${id}`, d),
    setEnter: data => http(`${path.live}/sett_entry_points`, data, "post")
  },
  card: {
    // 缩略图
    thumbnails: params => http(`${path.forum}/cards/course_thumb`, params),
    // 生成卡片
    create: (id, params) => http(`${path.forum}/cards/course_card/${id}`, params)
  },
  // 收藏
  collect: {
    add: data => http(`${path.course2}/course_favority`, data, "post"),
    del: id => http(`${path.course2}/course_favority/${id}`, {}, "delete")
  },
  //订单
  order: {
    //下单
    put: data => http(`${path.course2}/course_orders`, data, "post", false),
	virtualput: data => http(`${path.tt_common}/virtual_good_orders`, data, "post", false),
    pay: (data, isLogin = true) => {
      if (isLogin) return http(`${path.common}/order_pays`, data, "post", false);
      return http(`${path.tt_common}/orderpay/nologin`, data, "post", false);
    },
    list: (type, params) => http(`${path.tt_common}/${type}/orders`, params),
	
    course: {
      // 陪读下不同用户不同规格 价格不同根据用户获取 规格信息
      getPriceByUser: (course_id, uid) =>
        http(`${path.course2}/course_orders/help/${course_id}/${uid}`)
    },

    //延期报名
    yanqi: data => http(`${path.course2}/course_order_items/delay/${data.id}`, data, "post", false),

    info: (order_sn, params, isLogin = true) => {
      if (isLogin) return http(`${path.common}/order_pays/${order_sn}`, params);
      return http(`${path.tt_common}/orderpay/nologin/${order_sn}`, params);
    },
	// 获取用户的默认地址
	default: d => http(`${path.tt_common}/user_addresses/default_add`, d),
	// 获取用户的收获地址列表
	addreslist: d => http(`${path.tt_common}/user_addresses`, d),
	// 添加用户收获地址
	add: d => http(`${path.tt_common}/user_addresses`, d, "post"),
	// 更新用户地址
	edit: (id, d) => http(`${path.tt_common}/user_addresses/${id}`, d, "PATCH"),
	// 删除用户地址
	del: id => http(`${path.tt_common}/user_addresses/${id}`, {}, "DELETE"),
	// 获取用户的收获地址列表
	getId: id => http(`${path.tt_common}/user_addresses/${id}`),
	// 支付协议
	agreement: id => http(`${path.user_center}/project_agreements/${id}`),
  },
  //导师接口
  mentors: {
    //导师列表
    list: params => http(`${path.course2}/mentors`, params),
    //导师详情
    info: id => http(`${path.course2}/mentors/${id}`)
  },
  // 联系人
  consumer: {
    list: params => http(`${path.course2}/consumers`, params),
    info: id => http(`${path.course2}/consumers/${id}`),
    search: params => http(`${path.course2}/consumers/relations`, params),
    add: data => http(`${path.course2}/consumers`, data, "post"),
    del: id => http(`${path.course2}/consumers/${id}`, {}, "delete"),
    address: (id, data) => http(`${path.tt_common}/user_address/${id}`, data, "post"),
    getAddress: id => http(`${path.tt_common}/user_address/${id}`)
  },
  // 评论
  comments: {
    list: (id, d) => http(`${path.course2}/course_comments/${id}`, d),
    add: (id, d) => http(`${path.course2}/course_comments/${id}`, d, "post")
  },
  // 作业
  lessons: {
    list: d => http(`${path.course2}/user_course_tasks`, d),
    info: id => http(`${path.course2}/user_course_tasks/${id}`),
    add: (id, d) => http(`${path.course2}/user_course_tasks/${id}`, d, "post", false),
    //发布到社区广场
    publish: (id, d = {}) =>
      http(`${path.course2}/user_course_tasks/${id}/squares`, d, "post", false),
    publishAftre: (d = {}) => http(`${path.community}/tasks/check`, d),
    //删除发布的作业
    delPublish: (id, d = {}) => http(`${path.course2}/user_course_tasks/${id}/squares`, d, "delete")
    // delPublish: (d = {}) => http(`${path.community}/tasks/check`, d, 'delete'),
  },
  //打卡
  sign: {
    count: d => http(`${path.live}/sign_count`, d),
    card: d => http(`${path.forum}/cards/SignIn`, d, "post")
  },
  //秒杀
  seckill: {
    //首页秒杀列表
    home_seckill: () => http(`${path.course2}/courses?relation_key=home_page_flash_sale`)
  },
  article: {
    type: d => http(`${path.forum}/articles/magic`, d),
    list: d => http(`${path.forum}/articles`, d)
  }
};
export default course;
