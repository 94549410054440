import http from "./basic";
import path from "../path";

export default {
  //背景音乐分类
  musicClass: d => http(`${path.uc}/music/category/navigate`, d),
  //背景音乐 /background_musics?category_id=0
  musicById: d => http(`${path.uc}/background_musics`, d),
  //音频合成 
  processing: d => http(`${path.message}/audio/processing/merge`, d, "post"),
  //首页当天音乐
  days: d => http(`${path.uc}/days/innermost/words`, d),
  //播放次数 add
  addCount: (id, d) => http(`${path.uc}/collect_small_happinesses/listen_log/${id}`, d, 'POST'),


};
