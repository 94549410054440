const msy = [
	// {
	// 	path: "/",
	// 	name: "/",
	// 	meta: {
	// 		title: "有声传奇",
	// 	},
	// 	component: () => import("/src/components/tabs/tabs.vue")
	// },
	// {
	// 	path: "/",
	// 	name: "/",
	// 	meta: {
	// 		title: "有声传奇",
	// 	},
	// 	component: () => import("/src/views/home/home.vue")
	// },
	{
		path: "/",
		name: "/",
		meta: {
			title: "有声传奇",
			isbubble: true,
			keep: true,
			istabs: true,
		},
		component: () => import("/src/views/newhome/home/index.vue")
	},
	{
		path: "/my",
		name: "my",
		meta: {
			title: "我的主页",
			isbubble: true,
			keep: true,
			istabs: true,

		},
		component: () => import("/src/views/my/my/my.vue")
	},
	{
		path: "/home",
		name: "home",
		meta: {
			title: "有声传奇",
		},
		component: () => import("/src/views/home/home.vue")
	},
	{
		path: "/pay/success/:id/:pay_id",
		name: "success",
		meta: {
			title: "支付成功-有声传奇",
		},
		component: () => import("/src/views/msy/pay/success/success.vue")
	},

	// 作品的临时指向
	{
		path: "/student/lookWrite",
		name: "lookWrite",
		meta: {
			title: "有声传奇朗读作品",
		},
		component: () => import("/src/views/reader/works/works.vue")
	},

	// 作品页面
	{
		path: "/reader/works",
		name: "works",
		meta: {
			title: "妙声营-有声传奇",
		},
		component: () => import("/src/views/reader/works/works.vue")
	},
	{
		path: "/reader/recording1",
		name: "recording1",
		meta: {
			title: "妙声营-朗读",
		},
		component: () => import("/src/views/reader/recording1/recording1.vue")
	},
	{
		path: "/reader/recording2",
		name: "recording2",
		meta: {
			title: "妙声营-朗读",
		},
		component: () => import("/src/views/reader/recording2/recording2.vue")
	},
	{
		path: "/reader/synthesis",
		name: "synthesis",
		meta: {
			title: "合成 - 有声传奇",
		},
		component: () => import("/src/views/reader/synthesis/synthesis.vue")
	},
	{
		path: "/reader/publish",
		name: "publish",
		meta: {
			title: "发布作品 - 有声传奇",
		},
		component: () => import("/src/views/reader/publish/publish.vue")
	},
	// {
	// 	path: "/pay/orderpay/:id",
	// 	name: "orderpay",
	// 	meta: {
	// 		title: "发起支付 - 有声传奇",
	// 	},
	// 	component: () => import("/src/views/msy/pay/order-pay/order-pay.vue")
	// },
	// 测试支付 优惠卷 佣金等
	{
		path: "/pay/orderpay/:id",
		name: "orderpay",
		meta: {
			title: "发起支付 - 有声传奇",
		},
		component: () => import("/src/views/course/payment/index.vue")
	},
	{
		path: "/reader/list",
		name: "writings",
		meta: {
			title: "作品集 - 有声传奇",
			keep: true,
			savedPosition: 0
		},
		component: () => import("/src/views/reader/list/list.vue")
	},
	{
		path: "/reader/sounds",
		name: "sounds",
		meta: {
			title: "作品集 - 有声传奇",
			keep: true,
			savedPosition: 0
		},
		component: () => import("/src/views/reader/sounds/sounds.vue")
	},

	{
		path: "/reader/writing",
		name: "writing",
		meta: {
			title: "征集稿件",
		},
		component: () => import("/src/views/reader/writing/writing.vue")
	},
	{
		path: "/reader/myWritings",
		name: "my-writings",
		meta: {
			title: "我的投稿",
		},
		component: () => import("/src/views/reader/myWritings/myWritings.vue")
	},

	{
		path: "/reader/userSounds",
		name: "user-sounds",
		meta: {
			title: "用户的作品集",
		},
		component: () => import("/src/views/reader/userSounds/userSounds.vue")
	},
	// 妙声营1期
	{
		path: "/m1",
		name: "m1",
		meta: {
			title: "妙声营1期-有声传奇",
		},
		component: () => import("/src/views/msy/m1/index/index.vue")
	},
	{
		path: "/m1/home",
		name: "m1-home",
		meta: {
			title: "妙声营1期-有声传奇",
		},
		component: () => import("/src/views/msy/m1/home/home.vue")
	},
	{
		path: "/m1/report",
		name: "m1-report",
		meta: {
			title: "妙声营1期-有声传奇",
		},
		component: () => import("/src/views/msy/m1/report/report.vue")
	},

	// 妙声营2期
	{
		path: '/m2',
		name: 'm2',
		meta: {
			title: '妙声营2期-有声传奇',
		},
		component: () => import('/src/views/msy/m2/index/index.vue')
	},
	{
		path: "/m2/report",
		name: "m2-report",
		meta: {
			title: "妙声营2期-有声传奇",
		},
		component: () => import("/src/views/msy/m2/report/report.vue")
	},
	{
		path: "/m2/report/lucky",
		name: "m2-report-lucky",
		meta: {
			title: "妙声营2期结业狂欢",
		},
		component: () => import("/src/views/msy/m2/report/wheel/wheel.vue")
	},
	//妙声营3期
	{
		path: "/m3",
		name: "m3",
		meta: {
			title: "妙声营3期-有声传奇",
		},
		component: () => import("/src/views/msy/m3/index/index.vue")
	},
	{
		path: "/m3/home",
		name: "m3-home",
		meta: {
			title: "妙声营三期-有声传奇",
		},
		component: () => import("/src/views/msy/m3/home/home.vue")
	},
	{
		path: "/m3/report",
		name: "m3-report",
		meta: {
			title: "妙声营3期-有声传奇",
		},
		component: () => import("/src/views/msy/m3/report/report.vue")
	},
	//妙声营4期
	{
		path: "/m4",
		name: "m4",
		meta: {
			title: "妙声营4期-有声传奇",
		},
		component: () => import("/src/views/msy/m4/index/index.vue")
	},
	{
		path: "/m4/home",
		name: "m4-home",
		meta: {
			title: "妙声营4期-有声传奇",
		},
		component: () => import("/src/views/msy/m4/home/home.vue")
	},
	{
		path: "/m4/report",
		name: "m4-report",
		meta: {
			title: "妙声营4期-有声传奇",
		},
		component: () => import("/src/views/msy/m4/report/report.vue")
	},
	//妙声营5期
	{
		path: "/m5",
		name: "m5",
		meta: {
			title: "妙声营5期-有声传奇",
		},
		component: () => import("/src/views/msy/m5/index/index.vue")
	},
	{
		path: "/m5/home",
		name: "m5-home",
		meta: {
			title: "妙声营五期-有声传奇",
		},
		component: () => import("/src/views/msy/m5/home/home.vue")
	},
	{
		path: "/m5/report",
		name: "m5-report",
		meta: {
			title: "妙声营5期-有声传奇",
		},
		component: () => import("/src/views/msy/m5/report/report.vue")
	},
	//妙声营6期 缺省页
	{
		path: "/m6",
		name: "m6",
		meta: {
			title: "妙声营6期-有声传奇",
		},
		component: () => import("/src/views/msy/m6/index.vue")
	},
	// 临时指向
	{
		path: '/secondPhase',
		name: 'secondPhase',
		meta: {
			title: '妙声营2期-有声传奇',
		},
		component: () => import('/src/views/msy/m2/index/index.vue')
	},
	{
		path: "/m2/home",
		name: "m2-home",
		meta: {
			title: "妙声营2期-有声传奇",
		},
		component: () => import("/src/views/msy/m2/home/home.vue")
	},
	{
		path: "/m2/word",
		name: "m2-word",
		meta: {
			title: "妙声营2期-有声传奇",
		},
		component: () => import("/src/views/msy/m2/word/word.vue")
	},
	{
		path: "/m2/lucky",
		name: "m2-lucky",
		meta: {
			title: "助力抽取卡片",
		},
		component: () => import("/src/views/msy/m2/wheel/wheel.vue")
	},
]
export default msy