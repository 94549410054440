const home = [{
		path: "/course/:id",
		name: "course",
		meta: {
			title: "",
			isbubble: true
		},
		component: () => import("/src/views/course/course/index.vue")
	},
	{
		path: "/address",
		name: "address",
		meta: {
			title: "地址管理",
		},
		component: () => import("/src/views/course/address/address.vue")
	},
	{
		path: "/addressAdmin",
		name: "addressAdmin",
		meta: {
			title: "地址管理",
		},
		component: () => import("/src/views/course/address/addressAdmin.vue")
	},
	{
		path: "/payment",
		name: "payment",
		meta: {
			title: "支付订单",
		},
		component: () => import("/src/views/course/payment/index.vue")
	},
	{
		path: "/playsuccess/:id/:pay_id",
		name: "playsuccess",
		meta: {
			title: "支付完成",
		},
		component: () => import("/src/views/course/playsuccess/playsuccess.vue")
	},
	{
		path: "/read",
		name: "read",
		meta: {
			title: "朗读",
			isbubble: true,
			keep: true,
			istabs: true,
		},
		component: () => import("/src/views/newhome/read/read.vue")
	},
	{
		path: "/articleclass",
		name: "articleclass",
		meta: {
			title: "文章分类",
		},
		component: () => import("/src/views/articleClass/class/index.vue")
	},
	{
		path: "/classRead",
		name: "classRead",
		meta: {
			title: "",
		},
		component: () => import("/src/views/articleClass/classRead/classRead.vue")
	},
	{
		path: "/think",
		name: "think",
		meta: {
			title: "冥想",
		},
		component: () => import("/src/views/meditation/think/think.vue")
	},
	{
		path: "/study",
		name: "study",
		meta: {
			title: "",
			isbubble: false
		},
		component: () => import("/src/views/meditation/studyCourse/study.vue")
	},
	{
		path: "/myperson",
		name: "myperson",
		meta: {
			title: "互动中心",
		},
		component: () => import("/src/views/my/person/person.vue")
	},
	{
		path: "/learpark",
		name: "learpark",
		meta: {
			title: "学习园区",
		},
		component: () => import("/src/views/my/study/learpark.vue")
	},
	{
		path: "/comment",
		name: "comment",
		meta: {
			title: "作业",
		},
		component: () => import("/src/views/my/study/comment.vue")
	},
	// {
	// 	path: "/demo",
	// 	name: "demo",
	// 	meta: {
	// 		title: "demo",
	// 	},
	// 	component: () => import("/src/views/newhome/demo.vue")
	// },
	{
		path: "/write",
		name: "write",
		meta: {
			title: "写作业",
		},
		component: () => import("/src/views/my/gowrite/write.vue")
	},
	{
		path: "/welfare",
		name: "welfare",
		meta: {
			title: "福利社",
		},
		component: () => import("/src/components/common/welfare/welfare.vue")
	},
	{
		path: "/my/create",
		name: "create",
		meta: {
			title: "我的作品",
		},
		component: () => import("/src/views/my/create/create.vue")
	},
	{
		path: "/home/search",
		name: "search",
		meta: {
			title: "搜索",
		},
		component: () => import("/src/views/newhome/search/search.vue")
	},
	{
		path: "/my/setup",
		name: "setup",
		meta: {
			title: "设置",
		},
		component: () => import("/src/views/my/setup/set.vue")
	},
	{
		path: "/expectation",
		name: "expectation",
		meta: {
			title: "敬请期待",
		},
		component: () => import("/src/components/common/expectation/expectation.vue")
	},
	{
		path: "/nickname",
		name: "nickname",
		meta: {
			title: "",
		},
		component: () => import("/src/views/my/setup/nickname.vue")
	},
	{
		path: "/brief",
		name: "brief",
		meta: {
			title: "更新简介",
		},
		component: () => import("/src/views/my/setup/brief.vue")
	},
	{
		path: "/clockin",
		name: "clockin",
		meta: {
			title: "我的打卡",
		},
		component: () => import("/src/views/newhome/clockin/clockin.vue")
	},
	{
		path: "/message",
		name: "message",
		meta: {
			title: "我的消息",
		},
		component: () => import("/src/views/my/message/message.vue")
	},
	{
		path: "/message/info",
		name: "message/info",
		meta: {
			title: "消息详情",
		},
		component: () => import("/src/views/my/message/info.vue")
	},
	{
		path: "/realname",
		name: "realname",
		meta: {
			title: "实名认证",
		},
		component: () => import("/src/views/my/realname/realname.vue")
	},
	{
		path: "/soundvip",
		name: "soundvip",
		meta: {
			title: "有声大使",
		},
		component: () => import("/src/components/common/sound-vip/sound.vue")
	},
	{
		path: "/my/wallet",
		name: "wallet",
		meta: {
			title: "我的钱包",
		},
		component: () => import("/src/views/my/wallet/wallet.vue")
	},
	{
		path: "/walletInfo",
		name: "walletInfo",
		meta: {
			title: "佣金明细",
		},
		component: () => import("/src/views/my/wallet/walletInfo.vue")
	},
	{
		path: "/cash",
		name: "cash",
		meta: {
			title: "提现",
		},
		component: () => import("/src/views/my/cash/cash.vue")
	},
	{
		path: "/bankcard",
		name: "bankcard",
		meta: {
			title: "绑定银行卡",
		},
		component: () => import("/src/views/my/cash/bankcard.vue")
	},
	{
		path: "/sounlist",
		name: "sounlist",
		meta: {
			title: "",
		},
		component: () => import("/src/views/newhome/sounlist/sounlist.vue")
	},
	{
		path: "/live",
		name: "live",
		meta: {
			title: "直播间",
		},
		component: () => import("/src/views/live/live.vue")
	},
	{
		path: "/material",
		name: "material",
		meta: {
			title: "素材库",
		},
		component: () => import("/src/components/common/livecom/material.vue")
	}
]
export default home