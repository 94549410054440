import wx from "weixin-js-sdk"; //微信sdk依赖
import store from "@/store";
import http from "@/api/apis/mine";
import tool from "./index";
import qs from "qs";
import { showSuccessToast, showFailToast } from 'vant';
import router from "@/router";

let params = {
    share: {
        title: "有声传奇",
        desc: "我们的约定：用心发声，用爱陪伴，绽放自己，找到真我",
        images: "https://youshengchuanqi.oss-cn-shanghai.aliyuncs.com/picture/admin/system/resource/material/backend_1702639401699.png"
    }
};

const wechat = {
    async share(d, u) {
        params.share.link = this.link();
        d = d || params.share;
        await this.config();
        wx.ready(function () {
            // 分享给好友
            wx.updateAppMessageShareData({
                title: d.title,
                desc: d.desc,
                link: d.link,
                imgUrl: d.images,
                isShare: 1,
                card_id: 0,
                success: function () {
                    console.log(d.link, 'd.link')
                    // 设置成功
                    // alert('设置分享好友成功' + d.link)
                }
            });

            // 分享到朋友圈
            wx.updateTimelineShareData({
                title: d.title,
                link: d.link,
                imgUrl: d.images,
                isShare: 1,
                card_id: 0,
                success: function (res) {
                    // resolve(res)
                    // alert('设置分享朋友圈成功' + d.link)
                }
            });
        });
        wx.error(function () {
            // console.log(JSON.stringify(res) + "微信验证失败");
            // alert(JSON.stringify(res)+"微信验证失败");
        });

    },
    code(type, url, state) {
        this.config().then(r => {
            let scope = type === 1 ? "snsapi_userinfo" : "snsapi_base";
            !state && (state = type === 1 ? "wx-code-info" : "wx-code");
            if (!url) {
                url = window.location.href;
            }
            const obj = tool.http.urlParse(url);

            delete obj.code;
            delete obj.state;
            url = url.split("?")[0] + "?" + qs.stringify(obj);
            let params = qs.stringify({
                appid: r.appId,
                redirect_uri: url || window.location.href,
                response_type: "code",
                scope: scope,
                // forcePopup: true,
                // forceSnapShot: true,
                state: state
            });
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?${params}#wechat_redirect`;
        });
    },
    config() {
        return new Promise((resolve, j) => {
            let u = window.location.href;
            http.wechat
                .jssdk(u)
                .then(r => {
                    wx.config({
                        // debug: process.env.NODE_ENV == 'development' ? true : false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        debug: false,
                        appId: r.appId, // 必填，公众号的唯一标识
                        timestamp: r.timestamp, // 必填，生成签名的时间戳
                        nonceStr: r.nonceStr, // 必填，生成签名的随机串
                        signature: r.signature, // 必填，签名
                        jsApiList: r.jsApiList // 必填，需要使用的JS接口列表
                    });
					localStorage.setItem("wxappId",  r.appId);
                    resolve(r);
                })
                .catch(e => {
                    console.log('失败');
                    j(e)
                });
        });
    },
    link(source = "common", type = "LINK", channel = "common") {
        let url,
            urlParse = tool.http.urlParse();
        let user = tool.storage.get("user");
        let card_id = tool.storage.get("card_id");
        let view_uid = tool.storage.get("view_uid");
        let view_uid_encrypt = tool.storage.get("view_uid_encrypt");
        // console.log(user)

        if (urlParse && urlParse.referrer_uid) {
            url = user
                ? tool.http.paramsUpdate("referrer_uid", user ? user.id : 0)
                : window.location.href;
            // alert(url)
        } else {
            // alert('user', user) 
            // 添加URL的referrer_uid
            url = user
                ? tool.http.urlAdd({
                    source: source,
                    type: type,
                    referrer_uid: user.id,
                    referrer: user.id,
                    isShare: 1,
                    channel: channel,
                    card_id: card_id || 0,
                    view_uid: view_uid || 0,
                    view_uid_encrypt: view_uid_encrypt || 0
                })
                : window.location.href;
        }
        return url;
    },
    payment(jsapi, d) {
        return new Promise((r, j) => {
            this.config().then(r => {
                wx.chooseWXPay({
                    ...jsapi,
                    timestamp: jsapi.timeStamp,
                    success: result => {
                        // 支付成功 回调
                        showSuccessToast('支付成功');
                        router.replace(
                            `/pay/success/${d.id}/${d.pay_id}?use_channel=${d.use_channel}&help_code=${d.help_code}`
                        );
                        store.state.showLoadingPay.close()
                        r(result);
                    },
                    fail: err => {
                        showFailToast('支付失败');
                        j(err);
                    },
                    complete: res => {
                        console.log(res);
                        if (res.errMsg == "chooseWXPay:cancel") {
                            j({
                                cancel: true
                            });
                        }
                    }
                });
            })
        });
    },

    // 图片点击放大预览
    previewImage(current, imgs, isParent = false) {
        return new Promise(resolve => {
            if (isParent) {
                parent.WeixinJSBridge.invoke("imagePreview", {
                    urls: imgs,
                    current: current
                });
            } else {
                this.config();
                wx.previewImage({
                    current: current, //当前显示图片的http链接
                    urls: imgs //需要预览图片http链接列表
                });
            }
        });
    },
    closeWindow() {
        wx.closeWindow();
    },
    // 封装录音方法
    async startRecord(callback, er) {
        // await this.config();
        // console.log("方法")
        wx.ready(function () {
            wx.startRecord({
                success: function () {
                    callback();
                },
                fail: function (res) {
                    er(res);
                    console.log(res)
                }
            });
        })
    },

    // 停止录音方法
    async stopRecord(callback) {
        // await this.config();
        wx.ready(function () {
            wx.stopRecord({
                success: function (res) {
                    console.log("停止录音");
                    callback(res);
                },
                fail: function (res) {
                    // alert("停止录音失败：" + JSON.stringify(res));
                }
            });
        });
    },
    // 录音自动结束
    async onVoiceRecordEnd(callback) {
        // await this.config();
        wx.ready(function () {
            wx.onVoiceRecordEnd({
                // 录音时间超过一分钟没有停止的时候会执行 complete 回调
                complete: function (res) {
                    callback(res)
                }
            });
        })
    },

    // 播放录音方法
    async playRecord(localId, callback) {
        // await this.config();
        wx.ready(function () {
            wx.playVoice({
                localId: localId,
                success() {
                    console.log('播放')
                    callback(localId)

                }
            });
        });
    },
    // 停止播放录音方法
    async stopReplay(localId) {
        // await this.config();
        wx.ready(function () {
            wx.stopVoice({
                localId: localId,
                success() {
                    console.log('停止播放')


                }
            });
        });
    },
    // 自动播放录音结束方法
    async onVoicePlayEnd(callback) {
        wx.ready(function () {
            wx.onVoicePlayEnd({
                success(res) {
                    callback(res);
                    // console.log('onVoicePlayEnd')
                    // alert('播放结束') 
                }
            });
        });
    },
    // 上传录音方法
    uploadRecord(localId) {
        return new Promise((resolve, reject) => {
            this.config().then(() => {
                wx.ready(() => {
                    wx.uploadVoice({
                        localId: localId,
                        isShowProgressTips: 1,
                        success: function (res) {
                            var serverId = res.serverId;
                            resolve(serverId); // 将serverId传递给Promise的resolve
                        },
                        complete: function () {
                            reject(new Error("上传录音失败"));
                        }
                    });
                });
            }).catch(error => {
                reject(error);
            });
        });
    },
    wx: wx


};
export default wechat;
