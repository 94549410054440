<template>
	<div>
		<div class="bot_box" v-show="$route.meta.istabs">
			<div class="box_div" v-for="(item,index) in list" :key="index" @click="tabBtn(item,index)">
				<img :class="'img'+index" :src="$route.path == item.path ? item.urlac : item.url " />
			</div>
			<p class="tabs_img" v-if="$route.path == '/read'">
				点击开始朗读
			</p>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				active: 0,
				list: [{
						urlac: require("./image/homeactive.png"),
						url: require("./image/home.png"),
						path: "/"
					},
					// {
					// 	urlac: require("./image/shoppingactive.png"),
					// 	url: require("./image/shopping.png"),
					// 	path: ""
					// },
					{
						urlac: require("./image/readactive.png"),
						url: require("./image/read.png"),
						path: "/read",
						isurl: false
					},
					{
						urlac: require("./image/meactive.png"),
						url: require("./image/me.png"),
						path: "/my"
					}
				],
				isactive: {},
				selectedTab: null,
			}
		},
		mounted(){
			
		},
		methods: {
			tabBtn(item, i) {
				this.active = i
				this.$router.push({
					path: item.path
				});
				// 处理tabs二次点击事件处理
				this.active != 1 && (this.isactive = {})
				if (this.isactive[item] === 1) {
					// 这里处理第二次点击的逻辑
					// console.log("进入朗读页")
					this.$store.state.isread = !this.$store.state.isread
				}else {
					// console.log(this.isactive[item])
					if(item.path == "/read" && this.isactive[item]){
						this.$store.state.isread = !this.$store.state.isread
					}else {
						this.$store.state.isread = null
					}
				}
				this.active == 1 && (this.isactive[item] = (this.isactive[item] || 0) + 1)
				this.selectedTab = item;
			}
		}
	};
</script>

<style scoped lang="scss">
	.bot_box {
		width: 100%;
		height: 60px;
		// overflow: hidden;
		box-shadow: 0px 0px 20px 0px rgba(220, 220, 220, 0.4), inset 0px 0px 8px 0px rgba(199, 199, 199, 0.5);
		background: #FFFFFF;
		border-radius: 30px;
		position: fixed;
		z-index: 999;
		bottom: 0;
		left: 0;
		display: flex;
		.box_div {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			
		}
		.tabs_img{
			width: 108px;
			height: 45px;
			position: absolute;
			top: -36px;
			left: 130px;
			background-image: url(./image/tabsimg.png);
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: 12px;
			color: #917642;
			text-align: center;
			line-height: 33px;
		}

	}

	.img0 {
		width: 22px;
		height: 24px;
	}

	.img1 {
		// 添加上商城时解开注释并删除样式
		// width: 21px;
		// height: 24px;
		width: 24px;
		height: 24px;
	}

	.img2 {
		// 添加上商城时解开注释并删除样式
		width: 22px;
		height: 21px;
		
		// width: 24px;
		// height: 24px;
	}

	.img3 {
		width: 22px;
		height: 21px;
	}
</style>