const more = [{
	path: "/debug/:password",
	name: "debug",
	meta: {
		title: "",
		nologin: true
	},
	component: r => import('/src/views/debug/debug.vue')
},

/**
 * apply 应用
 */
{
	// 大转盘
	path: "/lucky/wheel",
	name: "wheel",
	meta: {
		title: "除夕夜狂欢",
	},
	component: r => import('/src/views/apply/lucky/wheel/wheel.vue')
},
{
	// 大转盘
	path: "/robot/index",
	name: "robot",
	meta: {
		title: "Hello，我是妙妙",
	},
	component: r => import('/src/views/apply/robot/index/index.vue')
},
{
	// 大转盘
	path: "/qtgs/index",
	name: "qtgs",
	meta: {
		title: "河北蕾汇农业科技有限公司",
	},
	component: r => import('/src/views/apply/qtgs/index.vue')
},
{
	path: "/questionnaire/write/:id",
	name: "questionnaireWrite",
	meta: {
		title: "我的问卷调查"
	},
	component: r => import('/src/views/apply/write/write.vue')
},
{
	path: "/login-way",
	name: "login-way",
	meta: {
		title: "登录"
	},
	component: r => import('/src/views/mine/login-way/login-way.vue')
},
]
export default more