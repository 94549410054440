import {
	showToast,
	showSuccessToast,
	showFailToast,
	showLoadingToast,
	closeToast,
	showNotify,
	closeNotify,
	showDialog
} from 'vant';
const tc = {
	/**
	 * this.$tc.dialog("提示","内容")
	 * @param {*} tip 
	 * @param {*} isCall 可以是字符串，boolean，回调方法
	 */
	dialog(tip = "提示", isCall, button = '确认') {
		let setting = {
			theme: 'round-button',
			confirmButtonText: button,
			confirmButtonColor: "#434343"
		}
		let isObj = false
		switch (typeof isCall) {
			case 'function':
				setting.title = tip;
				isObj = true
				break;
			case 'string':
				setting.title = tip;
				setting.message = isCall;
				break;
			default:
				if (!isCall || isCall == 'undefined' || isCall == 'null') {
					setting.message = tip;
				}
				break;
		}
		showDialog(setting).then(() => {
			isObj && isCall()
		});
	},
	top(text = '') {
		showNotify({
			type: 'warning',
			message: text,
		});
	},
	show(text = '') {
		showToast(text);
	},
	suc(text = '正确') {
		showSuccessToast(text);
	},
	err(text = '错误') {
		showFailToast(text);
	},
	// 加载中
	loading(msg = '光速加载中...') {
		return showLoadingToast({
			message: msg,
			forbidClick: true,
			loadingType: "spinner",
		});
	},
	countdown(text = `倒计时 3 秒`, callback) {
		console.log(typeof callback);
		let match = text.match(/\d+/g);
		let second
		match && (second = parseInt(match[0]));
		if (typeof second != "number") {
			second = 3
		}

		const toast = showLoadingToast({
			duration: 0,
			forbidClick: true,
			message: text,
		});

		const timer = setInterval(() => {
			second--;
			if (second >= 1) {
				toast.message = text.replace(/[0-9]/ig, second);
			} else {
				if (typeof callback == 'function') {
					callback();
				}
				clearInterval(timer);
				closeToast();
			}
		}, 1000);
	},
	close() {
		closeToast();
		closeNotify();
	},
}
export default tc