import http from './basic'
import tool from '@/common/tool'
import path from '../path'

const token = {
    overtime() {
        if (this.isExpired()) {
            http(`${path.user_center}/authorizations/current`, {}, 'PUT').then(r => {
                // 重新设置token
                tool.storage.set(
                    "login-token",
                    `${r.token_type} ${r.access_token}`
                );
                tool.storage.set("login-expires", r.expires_in);
                tool.storage.set("login-current", tool.time.timestamp());
            })
        }
    },
    isExpired() {
        let loginExpires = tool.storage.get('login-expires') - 300 // 到期时间段
        let loginCurrent = tool.storage.get('login-current') || 0 // 登录时间
        if (loginCurrent > 0 && (tool.time.timestamp() - loginCurrent) >= loginExpires) {
            return true
        } else {
            return false
        }
    }
}
export default token