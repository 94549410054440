import http from "./basic";
import path from "../path";

export default {
	// 个人中心
	user: {
		info: d => http(`${path.uc}/users/current`, d),
		// 获取背景图
		back: d => http(`${path.uc}/project_users/current`, d),
		// 更换背景图
		upbak: d => http(`${path.uc}/project_users/setting/mine_bg`, d, 'post'),
		// 个人中心 关注 粉丝 点赞获赞
		getnavig: d => http(`${path.community}/navigations`, d),
		// 获赞
		getlike: d => http(`${path.uc}/interact_center/get_like`, d),
		// 点赞
		setlike: d => http(`${path.uc}/interact_center/set_like`, d),
		// 粉丝列表
		fanslist: d => http(`${path.community}/sq_follows/fans`, d),
		// 关注列表
		mylist: d => http(`${path.community}/sq_follows/my?page=1`, d),
		// 关注用户
		addfollows: d => http(`${path.community}/sq_follows/add`, d, 'post'),
		// 移除关注
		delfollows: d => http(`${path.community}/sq_follows/remove_fans`, d, 'post'),
		// 取消关注
		remfollows: d => http(`${path.community}/sq_follows/del`, d, 'post'),
		// 学习园区 正在学习
		Learning: d => http(`${path.course2}/user_listen_courses`, d),
		// 创作园区-分类
		createCla: d => http(`${path.uc}/users/works/classes/navigations?id=0`, d),
		// 子分类
		structures: d => http(`${path.uc}/users/works/classes/structures?id=${d}`, ),
		// 创作园区-列表
		userSounds: (classid, uid, d) => http(
			`${path.uc}/collect_small_happinesses?class_id=${classid}&sort_type=sound_uid&doc_id=>0&uid=${uid}`, d),
		orderlist: d => http(`${path.tt_common}/course/orders`, d),
		// 个人认证-详细信息
		personalInfo: (d) => http(`${path.user_center}/personal_certification`),
		// 个人认证
		personalAuth: (d = {}) => http(`${path.user_center}/personal_certifications`, d, 'POST'),
		// 短信验证码
		verificationCodes: (d) => http(`${path.user_center}/verificationCodes`, d, 'POST'),
		// 更新地址
		address:d => http(`${path.tt_common}/virtual_good_orders/update_address`, d, 'POST'),
		addresscoure:d => http(`${path.course2}/course_orders/update_address`, d, 'POST'),
	},
	currency: {
		num: (d) => http(`${path.user_center}/my_currencies`, d), // 我的水晶数量
		moneyinfo: d => http(`${path.user_center}/currency_logs`, d), 
		watchPrice: (d) => http(`${path.user_center}/cash_info`, d),
		// 添加银行卡
		addBanks: (id, d) => http(`${path.uc}/users/${id}/banks`, d, "POST"),
	},
	userSet: {
		// 修改传奇号/昵称
		edit: (d) => http(`${path.user_center}/user`, d, 'PATCH', true),
		// 修改简介
		editIntroduce: (d) => http(`${path.uc}/project_users/introduce`, d, 'PATCH'),
		// 修改头像 参数custom_avatar: "https://youshengchuanqi-dev.oss-cn-shanghai.aliyuncs.com/picture/user_center/project_users/avatars/17151627661511747523891893785.png"
		// 上传oss 文件夹名称uc/avatar
		// https://api.xinghuishengyuan.com/user_center/api/user
	},
	// 作业
	school: {
		// oss上传后回显
		materials: d => http(`${path.user_center}/materials`, d),
		// 作业列表
		list: d => http(`${path.course2}/user_course_tasks`, d),
		// 作业分类
		allCourse: (d) => http(`${path.course2}/user_course_tasks/more/group_filters`, d),
		// 作业详情 / 提交作业
		schooladd: (d, data) => http(`${path.course2}/user_course_tasks/${d}`, data, 'POST'),
		schoolInfo: (d, data) => http(`${path.course2}/user_course_tasks/${d}`, data, ),
		// 点评详情
		squareInfo: (id = {}) => http(`${path.course2}/user_course_tasks/index2/${id}`),
		// submittask:d => http(`${path.course2}/user_course_tasks/${d}`),
	},
	message: {
		// 消息列表
		list: d => http(`${path.wechat}/informa`, d),
		info: d => http(`${path.wechat}/informa/${d}`),
		allRead: (d) => http(`${path.wechat}/informa_allread`, d),
	},
	addres: {
		// 城市列表(返回所有值)
		async areas_format(d) {
			return new Promise(resolve => {
				http(`${path.tt_common}/area_city`, d).then(r => {
					let area = [];
					r.map((v, k) => {
						if (v.parent_id === 0) {
							area.push({
								value: v.id,
								text: v.name,
								parent_id: v.parent_id,
								id: v.id,
								children: []
							});
						}
					});

					r.map((v, k) => {
						area.map((v1, k1) => {
							// console.log(v1)
							if (v1.id == v.parent_id) {
								area[k1].children.push({
									value: v.id,
									text: v.name,
									parent_id: v.parent_id,
									id: v.id,
									children: []
								});
							}
						});
					});

					r.map((v, k) => {
						area.map((v1, k1) => {
							area[k1].children.map((v2, k2) => {
								if (v2.id == v.parent_id) {
									area[k1].children[k2].children.push({
										value: v.id,
										parent_id: v.parent_id,
										id: v.id,
										text: v.name
									});
								}
							});
						});
					});
					resolve(area);
				});
			});
		}
	}
}