import http from "./basic";
import path from "../path";

const questionnaire = {
  list: params => http(`${path.survey}/exams`, params),
  detail: (id, params) => http(`${path.survey}/exams/${id}`, params),
  submit: d => http(`${path.survey}/exams`, d, "POST"),
  topicList: (id, params) => http(`${path.survey}/manager/exam_questions/${id}`, params),
  answerList: (id, params) => http(`${path.survey}/manager/exam_answers/${id}`, params),
  iswrite: activity => http(`/uc/api/activity_check?code=${activity}`),
  answeListTow: (id, d) => http(`${path.uc}/manager/exam_questions/${id}`, d),
  answeDetail: (id, d) => http(`${path.uc}/manager/exam_answers/${id}`, d),

  topics: {
    detail: d => http(`${path.forum}/my_comment`, d),
    list: d => http(`${path.forum}/topics`, d),
    details: (id, d) => http(`${path.forum}/topics/${id}`, d),
    add: d => http(`${path.forum}/comments`, d, "POST"),
    commentList: d => http(`${path.forum}/comments`, d),
    like: d => http(`${path.forum}/praises`, d, "POST"),
    updateDeail: id => http(`${path.forum}/comments/${id}`),
    update: (id, d) => http(`${path.forum}/comments/${id}`, d, "POST")
  },
  //年度总结入口是否显示
  navigations: () => http(`${path.community}/navigations`),
  //生成海报
  poster: d => http(`${path.report}/report_poster2021`, d, "POST"),
  //年度总结
  newYear: (view_uid, view_uid_encrypt) =>
    http(
      `${path.report}/report_summary2021?view_uid=${view_uid}&view_uid_encrypt=${view_uid_encrypt}`
    ),
  //查看自己的年度总结
  // newYearMyself: (view_uid, view_uid_encrypt) => http(`${path.report}/report_summary2021`),
  newYearMyself: d => http(`${path.report}/summary/2022/h5`, d),
  //陪读推送
  check_user: () => http(`${path.uc}/alerts`),
  //关闭弹窗
  check_user_callback: d => http(`${path.uc}/alerts_callback`, d, "POST"),
  // check_user_callback: (name, type, value) => http(`${path.uc}/alerts_callback?name=${name}&type=${type}&value=${value}`),
  //填写过的问卷列表
  historyList: (id, data) => http(`${path.uc}/exams/${id}/users`, data),
  //添加银行卡
  addBanks: (id, d) => http(`${path.uc}/users/${id}/banks`, d, "POST"),
  //查看是否有抽奖资格
  getChance: data => http(`${path.report}/common/activity/202203`, data),
  //抽奖结果
  Lottery: d => http(`${path.report}/common/activity/202203`, d, "POST")
};
export default questionnaire;
